import * as AntdIcons from '@ant-design/icons';
import { Drawer, Flex, Menu, Typography } from 'antd';
import { DrawerProps } from 'antd/es/drawer/index';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useMedia } from 'react-use';
import { ReactComponent as ITVecturaIcon } from 'assets/icons/logo-vectura.svg';

import { routes } from 'routing/routes';
import { RoutesType } from 'routing/routesTypes';
import { camelize } from 'utils';
import { useAppVersion } from 'utils/hooks/useAppVersion';
import { modalStores, useStoreNavigate } from 'utils/store';

import { metaStore } from 'utils/store/MetaStore';
import MenuItem from 'antd/es/menu/MenuItem';
import SubMenu from 'antd/es/menu/SubMenu';
import { fnv1aHash } from 'smart/utils';
import {
    ASIDE_CLOSE_WIDTH,
    ASIDE_CLOSE_WIDTH_MOBILE,
    ASIDE_OPEN_WIDTH,
    EXCLUDE_ROUTINGS,
    THEME
} from '../constants';
import type { AsideNavigatonPanelProps, IMenuItem } from './types';

import { LeftMenuHeaderLabel, LeftMenuRouteLabel } from './ui';
import './Aside.scss';
import { LazyIcon } from '../../../../../smart/ui';

const { Text } = Typography;

const styles: DrawerProps['styles'] = {
    header: {
        padding: 0,
        backgroundColor: '#07204A',
        overflowX: 'hidden'
    },
    body: {
        padding: 0,
        backgroundColor: '#07204A',
        overflowX: 'hidden'
    },
    mask: {
        backdropFilter: 'blur(5px)'
    }
};

// Список маршрутов словарей
const dictionariesRoutes = Object.keys(routes).filter((route) => route === 'dictionaries');
// Отфильтрованный и отсортированный список маршрутов
const sortedRoutes = Object.keys(routes).filter(
    (route) => route !== 'baseRoutes' && route !== 'dictionaries' && route !== 'profile'
);

function sortMenu(menuItem: IMenuItem | null) {
    if (!menuItem) return null;

    if (menuItem?.children) {
        menuItem.children.sort((a, b) => a?.path?.localeCompare(b.path || '') || 0);
        menuItem.children.map((child) => sortMenu(child));
    }

    return menuItem;
}

function sortMetaMenu(menuItem: IMenuItem) {
    if (menuItem?.children) {
        menuItem.children.sort((a, b) => (a.child_index || 0) - (b.child_index || 0));
        menuItem.children.map((child) => sortMetaMenu(child));
    }

    return menuItem;
}

export const Aside = observer<AsideNavigatonPanelProps>(({ isOpenStatus, onClose }) => {
    // const { isScreenMd } = useResize();
    const appVersion = useAppVersion();
    const isMiddleTablet = useMedia('(max-width: 720px)');

    const open = isMiddleTablet ? isOpenStatus : true;

    const asideWidth = useMemo(() => {
        if (isOpenStatus) return ASIDE_OPEN_WIDTH;
        if (isMiddleTablet) return ASIDE_CLOSE_WIDTH_MOBILE;
        return ASIDE_CLOSE_WIDTH;
    }, [isOpenStatus, isMiddleTablet]);

    const {
        t,
        i18n: { language }
    } = useTranslation(); // Хук для перевода текста
    const storeNavigate = useStoreNavigate(); // Хук для навигации
    const location = useLocation();

    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            const disableOldMenu = await metaStore.getParam({ param_name: 'DISABLE_OLD_MENU' });
            // console.log('[Aside] DISABLE_OLD_MENU = ', disableOldMenu);

            const showSublevelIcons = await metaStore.getParam({
                param_name: 'SHOW_SUBLEVEL_ICONS'
            });
            // console.log('[Aside] SHOW_SUBLEVEL_ICONS = ', showSublevelIcons);

            const maxMenuLevel = await metaStore.getParam({ param_name: 'MAX_MENU_LEVEL' });
            // console.log('[Aside] MAX_MENU_LEVEL = ', maxMenuLevel);

            const dashboardsEnabled = await metaStore.getParam({
                param_name: 'DASHBOARDS_ENABLED'
            });
            // console.log('[Aside] DASHBOARDS_ENABLED = ', dashboardsEnabled);
        })();
    }, []);

    const maxMenuLevel = metaStore.meta.get('all')?.params?.MAX_MENU_LEVEL?.param_value;
    const showSublevelIcons = metaStore.meta.get('all')?.params?.SHOW_SUBLEVEL_ICONS?.param_value;
    const disableOldMenu = metaStore.meta.get('all')?.params?.DISABLE_OLD_MENU?.param_value;

    useEffect(() => {
        // Разбиваем URL-адрес на части
        const parts = location.pathname.split('/');

        // Определяем активные ключи пунктов и подпунктов
        const newActiveKeys = [];
        if (parts.length >= 2) {
            newActiveKeys.push(parts[1]); // Ключ пункта
            if (parts.length >= 3) {
                newActiveKeys.push(`sub${parts[2]}`); // Ключ подпункта
            }
        }

        // Устанавливаем активные ключи в состояние
        setActiveKeys(newActiveKeys);
    }, [location, location.pathname]);

    // Формирование header меню
    const createHeaderItem = useCallback(
        () => ({
            label: <LeftMenuHeaderLabel />,
            icon: <ITVecturaIcon className={`logo ${!isOpenStatus ? 'logo-close' : ''}`} />,
            key: 'header-menu',
            id: 'header-menu',
            theme: THEME,
            style: { marginBottom: isOpenStatus ? 9 : 0 }
        }),
        [isOpenStatus]
    );

    // Функция для создания пунктов на основе маршрутов
    const createRouteItems = useCallback(() => {
        const items: IMenuItem[] = [];

        sortedRoutes.forEach((route) => {
            const itemsForRoute: RoutesType[] = routes[route].filter(
                (item) =>
                    !item.path.includes('/:id') &&
                    !item.path.includes('?') &&
                    !EXCLUDE_ROUTINGS.includes(item.path)
            );

            const routeLabel = (
                <LeftMenuRouteLabel
                    isOpen={isOpenStatus}
                    text={t(`${route.replace(/_/g, '-')}`)}
                    onClick={() => storeNavigate(routes[route][0].path)}
                    buttonId={`${routes[route][0].path}-overview`}
                />
            );

            const [{ icon: itemIcon }] = routes[route];

            const itemChildren = itemsForRoute.map(({ icon, path }) => ({
                label: (
                    <Text className="left-menu-text">
                        {t(
                            `${path
                                .split('/')
                                .filter((i) => !i.includes('?'))
                                .pop()!}`
                        )}
                    </Text>
                ),
                key: `sub${path.split('/').pop()!}`,
                id: `sub${path.split('/').pop()!}`,
                // icon,
                icon: null,
                onClick: () => storeNavigate(path),
                theme: THEME,
                // path: '',
                path: t(
                    `${path
                        .split('/')
                        .filter((i) => !i.includes('?'))
                        .pop()!}`
                ),
                children: undefined
            }));

            items.push({
                theme: THEME,
                label: routeLabel,
                key: route,
                id: route,
                icon: itemIcon || <LazyIcon icon={'FileOutlined'} />,
                children: isOpenStatus
                    ? itemChildren
                    : [
                          {
                              children: itemChildren,
                              label: routeLabel,
                              id: route,
                              type: 'group',
                              icon: null,
                              key: undefined,
                              //   path: undefined
                              path: t(`${route.replace(/_/g, '-')}`)
                          } as IMenuItem
                      ],
                // path: ''
                path: t(`${route.replace(/_/g, '-')}`) as string
            });
        });

        return items;
    }, [isOpenStatus, t, storeNavigate]);

    // Функция для создания пункта словарей
    const createDictionariesItem = useCallback((): IMenuItem | null => {
        if (dictionariesRoutes) {
            return {
                label: <Text className="left-menu-text">{t('dictionaries')}</Text>,
                key: 'dictionaries',
                id: 'dictionaries',
                icon: <AntdIcons.BookOutlined className="icon-dicts" />,
                onClick: () => storeNavigate('/dictionaries'),
                theme: THEME,
                path: t('dictionaries') || '/dictionaries'
            };
        }

        return null;
    }, [storeNavigate, t]);

    const createMetaRouteItems = useCallback(() => {
        // ### meta menu items ###
        const items: IMenuItem[] = [];

        const menu = toJS(metaStore.meta.get('all')?.menu);

        const notHiddenItems = menu?.items.filter((m) => !m.is_hidden);
        // console.log('[Aside] meta menu:', menu);
        const map: Record<string, any> = {};

        // только НЕ скрытые
        for (const item of notHiddenItems || []) {
            // const itemCode = item.code || camelize(item.name.en);
            const itemCode = camelize(item?.name?.en || '');
            const itemLabel = item.name[language] || t(itemCode);

            // перменные, необходимые для навигации
            let pathname = '';
            let filterString = '';

            // если по пункту меню можно навигироваться, то выполняем логику ниже (заполняем переменные выше)
            if (item.path) {
                // прилетает строка вида "/orders-management/transportation-orders?type_code=eq.PURCHASE_ORDER"
                // разделяем по знаку '?' - первый элемент это наш pathname, второй - фильтры
                const [path, filterStr] = item.path.split('?');
                pathname = path;
                filterString = filterStr;
            }

            const handleClickMenu = () => {
                // console.log('onClick menu');
                if (pathname && filterString) {
                    storeNavigate(
                        { pathname },
                        {
                            state: {
                                filterString,
                                pageTitle: itemLabel,
                                cacheKey: fnv1aHash(`${item.id}_${item.name?.[language]}`)
                            }
                        }
                    );
                } else if (pathname && !filterString) {
                    storeNavigate(
                        { pathname },
                        {
                            state: {
                                pageTitle: itemLabel,
                                cacheKey: fnv1aHash(`${item.id}_${item.name?.[language]}`)
                            }
                        }
                    );
                }
            };

            const itemLabelMarkup =
                item.is_folder && pathname ? (
                    <LeftMenuRouteLabel
                        isOpen={isOpenStatus}
                        text={itemLabel}
                        onClick={handleClickMenu}
                        buttonId={`${t(itemCode)}-overview`}
                    />
                ) : (
                    <Text className="left-menu-text">{itemLabel}</Text>
                );

            map[item.id] = {
                id: item.id,
                key: item.id,
                icon:
                    item.is_folder && !item.parent_id ? (
                        <LazyIcon icon={item.icon} />
                    ) : showSublevelIcons && item.icon ? (
                        <LazyIcon icon={item.icon} />
                    ) : null,
                theme: THEME,
                label: itemLabelMarkup,
                path: itemLabel,
                child_index: item.child_index,
                // folder option's settings
                onClick: item.is_folder ? undefined : handleClickMenu,
                children: item.is_folder ? [] : undefined,
                level: item.is_folder ? (item.parent_id ? 2 : 1) : 2
            };
        }

        if (maxMenuLevel) {
            for (const item of notHiddenItems || []) {
                if (item.parent_id && map[item.parent_id] && map[item.parent_id].children) {
                    map[item.id].level = map[item.parent_id].level + 1;
                    if (map[item.id]?.level <= maxMenuLevel) {
                        map[item.parent_id].children.push(map[item.id]);
                    }
                } else if (map[item.id]?.level <= maxMenuLevel) items.push(map[item.id]);
            }
        } else {
            for (const item of notHiddenItems || []) {
                if (item.parent_id && map[item.parent_id] && map[item.parent_id].children) {
                    map[item.parent_id].children.push(map[item.id]);
                } else items.push(map[item.id]);
            }
        }

        return items
            .sort((a, b) => (a.child_index || 0) - (b.child_index || 0))
            .map((item) => sortMetaMenu(item));
        // ! ### meta menu items ###
    }, [isOpenStatus, language, maxMenuLevel, showSublevelIcons, storeNavigate, t]);

    const items = useMemo(
        () => [
            ...(!disableOldMenu && disableOldMenu !== undefined
                ? [...createRouteItems(), createDictionariesItem()].map((item) => sortMenu(item))
                : []),
            ...createMetaRouteItems()
        ],
        [createDictionariesItem, createMetaRouteItems, createRouteItems, disableOldMenu]
    );

    const renderMenuItems = (items: IMenuItem[]) => {
        return items.map((item) => {
            if (item.children && item.children.length > 0) {
                return (
                    <SubMenu {...item} key={item.id} title={item.label}>
                        {renderMenuItems(item.children)}
                    </SubMenu>
                );
            }

            return <MenuItem {...item}>{item.label}</MenuItem>;
        });
    };

    return (
        <Drawer
            onClose={onClose}
            width={asideWidth}
            styles={styles}
            mask={isMiddleTablet}
            closable={isMiddleTablet}
            open={open}
            placement="left"
            className="aside"
        >
            <Menu
                mode="inline"
                inlineCollapsed={!isOpenStatus}
                items={[createHeaderItem(), ...items]}
                className="left-menu"
                selectedKeys={activeKeys}
            />
            <Flex
                className="aside__app-version"
                onClick={() => {
                    modalStores.changelog.open();
                }}
                justify="flex-end"
            >
                {appVersion}
            </Flex>
        </Drawer>
    );
});
