import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Card, Flex, List, Modal } from 'antd';
import { toJS } from 'mobx';
import { HandlerParam, MetaField } from 'modules/services/backend-api/generated_info';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { metaStore } from 'utils/store/MetaStore';

interface AllFiltersModalProps {
    meta: string;
    childParams: HandlerParam[];
    setData: (data: HandlerParam[]) => void;
    selectedIds: string[];
    handlerCode: string;
}

export const useChooseFieldsModal = ({
    meta,
    childParams,
    setData,
    selectedIds,
    handlerCode
}: AllFiltersModalProps) => {
    const [metaFields, setMetaFields] = useState<MetaField[]>([]);
    const [loading, setloading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const fetchFields = async () => {
            setloading(true);
            const response =
                toJS(metaStore.meta.get(meta)?.info) ?? (await metaStore.getInfo(meta));

            // console.log(response);

            if (response) {
                setMetaFields(response.Fields.filter((field) => !field.IsHiddenOnDetail));
            }

            setloading(false);
        };

        if (isOpen) fetchFields();
    }, [isOpen]);

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [activeData, setActiveData] = useState<MetaField[]>([]);
    // console.log('MODAL ACTIVE DATA', activeData);

    const childParamsMap = useMemo(
        () =>
            childParams.reduce(
                (acc, param) => ({ ...acc, [param.ParamName]: param }),
                {} as PlainObject<HandlerParam>
            ),
        [childParams]
    );

    useEffect(() => {
        // const activeData = metaFields?.filter((field) => childParamsMap[field.FieldName]) ?? [];

        // Когда заново открываем хэндлер, активные фильтры убираем
        setActiveData([]);
    }, [selectedIds]);

    const allFilters = useMemo(() => {
        const activeDataMap = activeData.reduce(
            (acc, data) => ({ ...acc, [data.ColumnName]: data, [data.FieldName]: data }),
            {} as PlainObject<MetaField>
        );

        return metaFields?.filter(
            (field) =>
                field.IsReadOnly === false &&
                !activeDataMap[field.ColumnName] &&
                !activeDataMap[field.FieldName] &&
                !childParamsMap[field.FieldName]
        );
    }, [activeData, childParamsMap, metaFields]);

    const removeAppliedField = useCallback((columnName: string) => {
        setActiveData((prevValue) => prevValue.filter((value) => value.ColumnName !== columnName));
    }, []);

    const handleCancel = () => {
        setActiveData([]);
        // setActiveData(metaFields?.filter((field) => childParamsMap[field.FieldName]) ?? []);
        setIsOpen(false);
    };

    const handleOk = () => {
        setData(
            activeData
                // .filter((data) => !childParamsMap[data.FieldName])
                .map((active) => ({
                    IsRequested: true,
                    ParamName: active.FieldName,
                    ValueType: active.ValueType,
                    ChildIndex: active.ChildIndex,
                    Id: active.Id,
                    Name: active.Name,
                    ParentHandler_Code: handlerCode
                }))
        );
        // setActiveData([]);
        setIsOpen(false);
    };

    return {
        openModal: () => setIsOpen(true),
        chooseFieldsModal: (
            <Modal
                open={isOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                okText={t('ok')}
                cancelText={t('cancel')}
                width="70%"
                destroyOnClose
                title={t('choose_fields')}
                zIndex={3000}
            >
                <Flex gap={5}>
                    <Card title={`${t('all_fields')}:`} style={{ width: '50%' }}>
                        <List
                            className="all_filters"
                            dataSource={allFilters || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            loading={loading}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        setActiveData((prev) => [...prev, item]);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Flex gap={15}>
                                        <PlusOutlined
                                            style={{
                                                cursor: 'pointer',
                                                color: '#3287d3'
                                            }}
                                        />
                                        {item.Name[language] || t(item.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List>
                    </Card>
                    <Flex
                        style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.25)' }}
                        align="center"
                        justify="center"
                        vertical
                    >
                        <span>{'>'}</span>
                        <span>{'<'}</span>
                    </Flex>
                    <Card title={`${t('active_fields')}:`} style={{ width: '50%' }}>
                        <List
                            className="active_filters"
                            dataSource={activeData || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            loading={loading}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        removeAppliedField(item.ColumnName);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Flex gap={15}>
                                        {!childParamsMap[item.FieldName] && (
                                            <MinusOutlined
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#fc5e53'
                                                }}
                                            />
                                        )}
                                        {item.Name[language] || t(item.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List>
                    </Card>
                </Flex>
            </Modal>
        )
    };
};
