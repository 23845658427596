import { Flex, Input, Select } from 'antd';
import { isUndefined } from 'is-lite/exports';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoMeta } from 'modules/services/backend-api/generated_models';
import { metaStore } from 'utils/store/MetaStore';
import { MetaField } from 'modules/services/backend-api/generated_info';
import { fieldEditRender, parseValueType } from 'smart/utils';
import { PlainObject } from '@gilbarbara/types';
// import { toJS } from 'mobx';
// import { SmartSelectField } from '../SmartSelectField/SmartSelectField';

interface ValueTypeField {
    value: string;
    typeValue: Record<string, any> | string;
    onChange: (value: any | undefined) => void;
    onTypeChange: (typeValue: string) => void;
    valueField: MetaField;
    valueTypeField?: MetaField;
    rootMeta?: string;
}

const primitiveTypeOptions = [
    { value: 'text' },
    { value: 'jsonb_multilang_text' },
    { value: 'bool' },
    { value: 'int16' },
    { value: 'int32' },
    { value: 'int64' },
    { value: 'float32' },
    { value: 'float64' },
    { value: 'object_id' },
    // { value: 'id' },
    { value: 'code' },
    { value: 'key' },
    { value: 'datetime' },
    { value: 'date' },
    { value: 'time' },
    { value: 'seconds' },
    { value: 'duration' },
    { value: 'decimal' },
    { value: 'jsonb' },
    { value: 'coordinate' },
    { value: 'color' },
    { value: 'barcode' },
    { value: 'value_type' },
    { value: 'table_name' },
    { value: 'local_datetime' },
    { value: 'composite_multilang_text' },
    { value: 'numeric' },
    { value: 'dates_range' },
    { value: 'local_datetime_range' },
    { value: 'point' },
    { value: 'polygon' },
    { value: 'time_range' },
    { value: 'year' },
    { value: 'geography' },
    { value: 'access_level' },
    { value: 'datetime_range' }
];

export const ValueTypeField: React.FC<ValueTypeField> = ({
    value,
    onChange,
    onTypeChange,
    valueTypeField,
    valueField,
    typeValue
    // rootMeta
}) => {
    const hasCustomTypeSource =
        valueTypeField?.FieldName === 'Property' || valueTypeField?.FieldName === 'Parameter';

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [forRefData, setForRefData] = useState<InfoMeta[]>([]);
    const [valueType, setValueType] = useState(
        hasCustomTypeSource ? (typeValue as PlainObject<string>)?.ValueType : typeValue
    );

    const [typeFilters, setTypeFilters] = useState('');
    const [selectSearch, setSelectSearch] = useState('');

    // useEffect wich partially works for update cycle only
    const isMountCycle = useRef(true);
    useEffect(() => {
        if (hasCustomTypeSource) {
            setValueType((prevValueType) => {
                const newValueType = (typeValue as PlainObject<any>)?.ValueType;
                const defaultValue = (typeValue as PlainObject<any>)?.DefaultValue;

                const valueNotEqualDefualt =
                    typeof value === 'object' && typeof defaultValue === 'object'
                        ? JSON.stringify(value) !== JSON.stringify(defaultValue)
                        : isUndefined(value);

                if (prevValueType !== newValueType || valueNotEqualDefualt) {
                    if (isMountCycle.current) {
                        isMountCycle.current = false;
                        // onChange(undefined);
                    } else onChange(defaultValue);
                }

                return newValueType;
            });
        } else {
            setValueType((prevValueType) => {
                if (!typeValue) return '';

                if (prevValueType && prevValueType !== typeValue) {
                    if (typeValue.startsWith('bool') || typeValue.startsWith('*bool')) {
                        onChange(false);
                    } else {
                        onChange(undefined);
                    }
                }

                const { options } = parseValueType(typeValue as string, language);

                setTypeFilters(options?.filters || '');

                return typeValue.split(';filters')[0] as string;
            });
        }
    }, [typeValue, hasCustomTypeSource, language]);

    const fetchForRefData = useCallback(async () => {
        try {
            const response = await metaStore.makeSelect({
                meta: 'InfoMeta',
                fields: ['Type', 'PluralName', 'Code'],
                filters: 'Type=in.[DOC,CAT,DCT,INF]',
                sort: 'Code asc'
            });

            // console.log(response);

            setForRefData((response?.objects as unknown as InfoMeta[]) || []);
        } catch (error) {
            console.error(error || 'Select InfoMeta error');
        }
    }, []);

    useEffect(() => {
        fetchForRefData();
    }, [fetchForRefData]);

    const selectOptions = useMemo(() => {
        const basic = primitiveTypeOptions
            .filter((o) => o.value.includes(selectSearch))
            .sort((a, b) => {
                if (a.value && b.value) {
                    return a.value.localeCompare(b.value, 'en');
                }

                return 0;
            });

        const ref = forRefData
            ?.map((item) => ({
                label: item.PluralName?.[language],
                value:
                    item.Type?.Code === 'DCT' || item.Type?.Code === 'INF'
                        ? `code;ref:${item.Code}.Code`
                        : // item.Type?.Code === 'CAT' || item.Type?.Code === 'DOC'
                          //   ?
                          `object_id;ref:${item.Code}.Id`
                //   : ''
            }))
            .filter((o) =>
                o.label ? o.label.includes(selectSearch) : o.value.includes(selectSearch)
            )
            // сортировка по алфавиту label
            .sort((a, b) => {
                if (a.label && !b.label) {
                    return -1;
                }
                if (!a.label && b.label) {
                    return 1;
                }
                if (!a.label && !b.label) {
                    return a.value.localeCompare(b.value, 'en');
                }
                if (a.label && b.label) {
                    return a.label.localeCompare(b.label, language === 'tech' ? 'en' : language);
                }

                return 1;
            });

        const result = [];

        if (basic.length)
            result.push({
                label: t('basic'),
                title: 'Basic',
                options: basic
            });
        if (ref.length)
            result.push({
                label: t('ref'),
                title: 'Ref',
                options: ref
            });

        return result;
    }, [forRefData, language, selectSearch, t]);

    const selectAddon = useMemo(() => {
        if (valueTypeField) {
            return (
                <Flex gap={5}>
                    <Select
                        showSearch
                        onSearch={setSelectSearch}
                        searchValue={selectSearch}
                        filterOption={false}
                        variant="filled"
                        style={{ minWidth: '50%' }}
                        value={valueType}
                        onChange={onTypeChange}
                        options={selectOptions}
                        onFocus={fetchForRefData}
                        popupMatchSelectWidth={250}
                        allowClear
                    />
                    <Input
                        value={typeFilters}
                        onChange={(e) => {
                            console.log(e.target.value);
                            onTypeChange(`${valueType};filters:${e.target.value}`);
                        }}
                    />
                </Flex>
            );
        }

        return null;
    }, [
        fetchForRefData,
        onTypeChange,
        selectOptions,
        selectSearch,
        typeFilters,
        valueType,
        valueTypeField
    ]);

    // console.log('final type', typeFilters ? `${valueType};filters:${typeFilters}` : valueType);
    console.log(typeValue, value);

    return (
        // <Flex gap={5} vertical style={{ width: 'calc(50% - 5px)' }}>
        <Flex gap={5} vertical>
            {/* shown only if has not custom value type sources, example: Parameters, Properties */}
            {!hasCustomTypeSource && (
                <div style={{ width: '100%', minWidth: 75 }}>{selectAddon}</div>
            )}

            <Flex
                align="center"
                style={{
                    width: !hasCustomTypeSource ? '100%' : '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {fieldEditRender({
                    data: value, // || typeof value === 'boolean' ? JSON.parse(value) : value,
                    onChange: (key, newValue: any) => {
                        let changedValue = newValue;

                        if (valueType && valueType.includes('jsonb')) {
                            onChange(changedValue);
                            return;
                        }

                        if (newValue && typeof newValue === 'object' && !Array.isArray(newValue))
                            changedValue = {
                                Id: newValue.Id,
                                Code: newValue.Code,
                                Name: newValue.Name
                            };
                        else if (Array.isArray(newValue))
                            // JSON.stringify(
                            changedValue = newValue?.map((v: any) => ({
                                Id: v.Id,
                                Code: v.Code,
                                Key: v.Key,
                                Name: v.Name,
                                Title: v.Title,
                                ShortTitle: v.ShortTitle,
                                PluralName: v.PluralName
                            }));
                        // )
                        else if (newValue || typeof newValue === 'boolean') changedValue = newValue; // JSON.stringify(newValue)

                        onChange(changedValue);
                    },
                    language,
                    metaFieldData: {
                        ...valueField,
                        ValueType: typeFilters ? `${valueType};filters:${typeFilters}` : valueType
                    }
                })}
            </Flex>
        </Flex>
    );
};
