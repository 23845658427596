import { useVT } from 'virtualizedtableforantd4';
import { useMedia, useWindowSize } from 'react-use';
import React, { useMemo } from 'react';

import { Dropdown } from 'antd';
import { useDoubleTap } from '../../../../utils/hooks';

export const useVirtualComponents = () => {
    const { height: windowHeight } = useWindowSize();

    const isBigMobile = useMedia('(max-width: 480px)');

    const [vt] = useVT(
        () => ({
            scroll: { y: windowHeight - 22 * 13.48 },
            overscanRowCount: 10,
            offset: 10
        }),
        []
    );

    const components = useMemo(
        () => ({
            ...vt,
            body: {
                ...vt.body,
                row: isBigMobile
                    ? (props) => {
                          const doubleTapBinder = useDoubleTap(props.onDoubleClick);

                          console.log('CALL');
                          return <vt.body.row {...props} onTouchStart={doubleTapBinder.onClick} />;
                      }
                    : (props) => {
                          return (
                              <Dropdown
                                  onOpenChange={props.onRightClick}
                                  trigger={['contextMenu']}
                                  menu={{ items: props.contextMenuItems }}
                                  destroyPopupOnHide
                              >
                                  <vt.body.row
                                      {...props}
                                      style={{
                                          backgroundColor: props.row?.isHardGroup
                                              ? '#fafafa'
                                              : undefined
                                      }}
                                      onDoubleClick={props.onDoubleClick}
                                      onClick={(e) => {
                                          if (e.shiftKey) {
                                              if (props.onShiftClick) props.onShiftClick();
                                          } else if (e.ctrlKey) {
                                              if (props.onCtrlClick) props.onCtrlClick();
                                          } else if (props.onClick) props.onClick();
                                      }}
                                  />
                              </Dropdown>
                          );
                      }
            }
        }),
        []
    );

    return components;
};
