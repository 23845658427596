import { createContext, useContext, ReactNode } from 'react';
import { createSupabaseClient } from './supabaseClient';
import { createKeycloakClient } from './keycloakClient';
import { AuthResponse } from '@supabase/supabase-js';
import { ApiClient } from './types';

// Создаем клиент в зависимости от переменной окружения AUTH_PROVIDER
const createClient = (): ApiClient => {
    const authProvider = window.env.AUTH_PROVIDER || 'supabase';

    if (authProvider === 'supabase') {
        return createSupabaseClient();
    } else if (authProvider === 'keycloak') {
        return createKeycloakClient();
    } else {
        throw new Error('Unsupported authentication provider');
    }
};

// Экспортируем созданный клиент
export const client = createClient();

// Создаем контекст и провайдер для работы с клиентом в React
export const ApiContext = createContext<ApiClient | null>(null);

export const useClient = (): ApiClient => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useAuthClient must be used within an AuthProvider');
    }
    return context;
};

export const ApiProvider = ({ children }: { children: ReactNode }) => {
    return <ApiContext.Provider value={client}>{children}</ApiContext.Provider>;
};
