import { PlusOutlined } from '@ant-design/icons';
import { Divider, Drawer, List, Space, TreeDataNode } from 'antd';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Dispatch, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectOnce, useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

import { Meta, MetaField } from 'modules/services/backend-api/generated_info';
import { getExcludeFieldsPattern } from 'smart/utils';
import { ButtonWithTooltips } from 'ui';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';
import { metaStore } from 'utils/store/MetaStore';

import { AllFiltersModal, FilterItem, SavedQueryControl, useSelectedQueries } from './components';
import { FilterField } from './types';
import { getInitialActiveFilters } from './utils';
import { useFilterToStringConverter } from '../../utils/hooks';

import './TableFilterMenuModal.scss';

interface ISmartTableFilterMenuModal {
    open?: boolean;
    setOpen?: Dispatch<boolean>;
    filters: FilterField[];
    setFilters: Dispatch<FilterField[]>;
    showInModal?: boolean;
    meta: string;
    filtersFromLayout: FilterField[];
}

interface DataNode extends TreeDataNode {
    data: Meta | MetaField;
    parentName: string | null;
    children: DataNode[];
    parentId: React.Key | string | null;
}

export const SmartTableFilterMenuModal: FC<ISmartTableFilterMenuModal> = observer(
    ({ open, setOpen, filters, setFilters, showInModal = true, meta, filtersFromLayout }) => {
        const metaInfo = metaStore.meta.get(meta)?.info;
        const metaFields = metaInfo?.Fields;
        const metaChilds = metaInfo?.ChildNodes;

        // // Пример данных для проверки
        // const fields2 = [{ FieldName: 'Key', Name: { ru: 'Ключ', en: 'Key' }, Id: 'uuid1' }];
        // const children = [
        //     {
        //         PluralName: { ru: 'Параметры', en: 'Parameters' },
        //         Id: 'uuid2',
        //         Fields: [{ FieldName: 'Flow', Name: { ru: 'Поток', en: 'Flow' }, Id: 'uuid3' }]
        //     }
        // ];

        // // Проверка результата
        // console.log(JSON.stringify(buildTree(fields2, children), null, 2));

        const isSmallTablet = useMedia('(max-width: 620px)');
        const isMiddleTablet = useMedia('(max-width: 720px)');
        const isBigMobile = useMedia('(max-width: 480px)');
        const isBigTablet = useMedia('(max-width: 1080px)');
        const isSmallDesktop = useMedia('(max-width: 1280px)');

        const { t } = useTranslation();
        const converter = useFilterToStringConverter();

        const [_, setSelectedQuery] = useSelectedQueries();
        const [appliedFilterFields, setAppliedFilterFields] = useState<FilterField[]>([]);
        const [openAllFiltersModal, setOpenAllFiltersModal] = useState(false);
        // Стейт для работы Saved Query
        const [filterString, setFilterString] = useState<string>('');

        // console.log('1', appliedFilterFields);

        // конвертируем ВЫБРАННЫЙ набор фильтров в строку для Saved Query
        useEffect(() => {
            // console.log('appliedFilterFields', appliedFilterFields);
            setFilterString(converter(appliedFilterFields));
        }, [appliedFilterFields]);

        const fields = useMemo(
            () =>
                // toJS(
                (metaFields || []).filter(
                    (field, _, fields) =>
                        !field.IsHiddenOnTable && getExcludeFieldsPattern(field, fields)
                ),
            // ),
            [metaFields]
        );

        const childNodes = useMemo(
            () =>
                // toJS(
                (metaChilds || [])
                    .filter((child) => !child.IsHidden)
                    .map((child) => ({
                        ...child,
                        Fields: child.Fields.filter(
                            (field, _, fields) =>
                                !field.IsHiddenOnTable && getExcludeFieldsPattern(field, fields)
                        )
                    })),
            // ),
            [metaChilds]
        );

        // console.log('!!!!', fields, childNodes);

        const drawerWidth = useMemo(() => {
            let width = '33%';

            if (isBigMobile) width = '100%';
            else if (isSmallTablet) width = '75%';
            else if (isMiddleTablet) width = '66%';
            else if (isBigTablet) width = '50%';

            return width;
        }, [isBigMobile, isBigTablet, isMiddleTablet, isSmallTablet]);

        const removeAppliedField = useCallback((columnName: string) => {
            setAppliedFilterFields((prevValue) => {
                const copy = [...prevValue];
                const fieldIndex = copy.findIndex((field) => field.field.ColumnName === columnName);

                if (fieldIndex >= 0) {
                    copy.splice(fieldIndex, 1);
                }

                return copy;
            });
        }, []);

        // функция для сброса фильтров при клике на кнопку drop в футере
        const dropAllAppliedField = useCallback(() => {
            // if (filters.length > 0) setFilters([]);
            setAppliedFilterFields(getInitialActiveFilters(fields, filtersFromLayout));
            setSelectedQuery(undefined);

            // if (setOpen) {
            //     setOpen(false);
            // }
        }, [fields, filtersFromLayout]);

        const dropAllAppliedFieldAndApply = useCallback(() => {
            if (filters.length > 0) setFilters([]);
            setAppliedFilterFields(getInitialActiveFilters(fields, filtersFromLayout));
            setSelectedQuery(undefined);

            if (setOpen) {
                setOpen(false);
            }
        }, [fields, filters.length, setFilters, filtersFromLayout]);

        const changeAppliedFilter = useCallback(
            (changedfield: FilterField) => {
                const copy = [...appliedFilterFields];
                const fieldIndex = copy.findIndex((field) => field.field === changedfield.field);

                if (fieldIndex >= 0) {
                    copy[fieldIndex] = {
                        ...copy[fieldIndex],
                        ...changedfield
                    };
                }

                if (copy[fieldIndex].values.length === 0) {
                    removeAppliedField(copy[fieldIndex].field.ColumnName);
                } else {
                    setAppliedFilterFields(copy);
                }
            },
            [appliedFilterFields, setAppliedFilterFields, removeAppliedField]
        );

        useEffectOnce(() => {
            if (filters.length) setAppliedFilterFields(filters);
        });

        useEffect(() => {
            if (!showInModal) {
                if (!isEqual(filters, appliedFilterFields) && appliedFilterFields.length > 0) {
                    setFilters(appliedFilterFields);
                }
            }
        }, [appliedFilterFields, showInModal, filters, setFilters]);

        const drawerContent = (
            <List
                className="applied_filters"
                dataSource={appliedFilterFields ?? []}
                locale={{ emptyText: <EmptyMarker size="small" /> }}
                itemLayout="horizontal"
                renderItem={(item) => (
                    <List.Item style={{ padding: 0 }}>
                        <FilterItem
                            item={item}
                            isNotRemovable={
                                !!(
                                    item.field.FilterIndex ||
                                    filtersFromLayout.find(
                                        (fl) => fl.field.FieldName === item.field.FieldName
                                    )
                                )
                            }
                            items={appliedFilterFields ?? []}
                            changeItem={changeAppliedFilter}
                            removeAppliedField={removeAppliedField}
                        />
                    </List.Item>
                )}
            ></List>
        );

        return (
            <>
                <Drawer
                    title={t('filtering')}
                    placement="right"
                    width={drawerWidth}
                    onClose={() => {
                        if (setOpen) {
                            setOpen(false);
                        }
                    }}
                    id="table_filter_menu"
                    open={open}
                    // destroyOnClose
                    footer={
                        <SavedQueryControl
                            filterString={filterString}
                            onChangeFilterString={setFilterString}
                            filters={appliedFilterFields}
                            onChangeFilters={setAppliedFilterFields}
                            meta={meta}
                        />
                    }
                    extra={
                        <Space>
                            <ButtonWithTooltips
                                id="drop"
                                className="btn-red"
                                onClick={dropAllAppliedFieldAndApply}
                            >
                                {t('drop_and_apply')}
                            </ButtonWithTooltips>

                            <ButtonWithTooltips
                                id="drop"
                                className=""
                                onClick={dropAllAppliedField}
                            >
                                {t('drop')}
                            </ButtonWithTooltips>

                            <ButtonWithTooltips
                                id="apply"
                                onClick={() => {
                                    setFilters(appliedFilterFields);

                                    if (setOpen) {
                                        setOpen(false);
                                    }
                                }}
                            >
                                {t('apply')}
                            </ButtonWithTooltips>
                        </Space>
                    }
                >
                    {drawerContent}
                    <Divider style={{ margin: '12px 0' }} />
                    <ButtonWithTooltips
                        id={'all_filters'}
                        type="default"
                        className=""
                        icon={<PlusOutlined />}
                        style={{ marginLeft: 'calc(100% - 92px)' }}
                        onClick={() => setOpenAllFiltersModal(true)}
                    >
                        {t('all_fields')}
                    </ButtonWithTooltips>
                </Drawer>

                <AllFiltersModal
                    meta={meta}
                    setOpen={setOpenAllFiltersModal}
                    open={openAllFiltersModal}
                    activeFilters={appliedFilterFields}
                    setActiveFilters={setAppliedFilterFields}
                    metaFields={fields}
                    metaChilds={childNodes}
                    filtersFromLayout={filtersFromLayout}
                />
            </>
        );
    }
);
