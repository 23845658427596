import React, { PropsWithChildren, memo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface LoaderProps extends PropsWithChildren {
    status?: boolean;
    style?: React.CSSProperties;
    forceDisableTip?: boolean;
    customTip?: string;
    size?: 'small' | 'default' | 'large';
}

const styles: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

export const Loader = memo(
    ({
        children,
        customTip,
        size = 'default',
        forceDisableTip = false,
        style = {},
        status = true
    }: LoaderProps) => {
        const { t } = useTranslation();

        const tip = children
            ? !forceDisableTip
                ? customTip || t('loading')
                : undefined
            : undefined;

        return (
            <Spin
                spinning={status}
                tip={tip}
                size={size}
                // indicator={<LoadingOutlined style={{ color: '#3185D2' }} />}
                style={!children ? { ...styles, ...style } : { ...style }}
            >
                {children}
            </Spin>
        );
    }
);
