import { FilterOption, SupabaseFilterOperator } from '../../../types';

export const mapMUITableFilterOperationAndSupabaseFilterOperation = (
    muiFilterOperation: FilterOption
): SupabaseFilterOperator => {
    switch (muiFilterOperation) {
        // FIXME: а зачем мы делаем разные тексты - зачем мы свой собственный язык изобретаем если уже есть супабейзовский? надо его и использовать - а так мы сами себе сложность изобрели!
        case 'equals':
            return 'eq';
        case 'greaterThan':
            return 'gt';
        case 'greaterThanOrEqualTo':
            return 'gte';
        case 'lessThanOrEqualTo':
            return 'lte';
        case 'lessThan':
            return 'lt';
        case 'contains':
            return 'like';
        case 'notEmpty':
            return 'neq';
        case 'empty':
            return 'is';
        case 'betweenInclusive':
            return 'adj';
        case 'between':
            return 'adj';
        case 'notEquals':
            return 'neq';
        // case 'endsWith': // NOTE: ilike ??
        //     return 'ilike';
        // case 'startsWith':
        //     return 'ilike';
        // case 'fuzzy':
        //     return 'ilike';
        case 'endsWith':
            return 'like';
        case 'startsWith':
            return 'like';
        case 'fts':
            return 'fts';
        case 'in_list':
            return 'in';
        case 'not_in_list':
            return 'notin';
        default:
            return muiFilterOperation;
    }
};

export const mapShortFilterOperation = (
    muiFilterOperation: FilterOption
): SupabaseFilterOperator => {
    switch (muiFilterOperation) {
        // FIXME: а зачем мы делаем разные тексты - зачем мы свой собственный язык изобретаем если уже есть супабейзовский? надо его и использовать - а так мы сами себе сложность изобрели!
        case 'eq':
            return 'equals';
        case 'gt':
            return 'greaterThan';
        case 'gte':
            return 'greaterThanOrEqualTo';
        case 'lte':
            return 'lessThanOrEqualTo';
        case 'lt':
            return 'lessThan';
        case 'like':
            return 'contains';
        case 'is':
            return 'empty';
        case 'adj':
            return 'between';
        case 'ne':
            return 'notEquals';
        case 'fts':
            return 'fts';
        case 'in':
            return 'in_list';
        case 'notin':
            return 'not_in_list';
        default:
            return muiFilterOperation;
    }
};
