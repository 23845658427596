import { decodeObjectGUID, decodeJWT } from 'utils/helpers/decode';
import {
    ApiClient,
    KeycloakAccessTokenDecodeType,
    KeycloakTokenResponseType,
    Session
} from './types';

// Функция для создания клиента с поддержкой Keycloak
export const createKeycloakClient = (): ApiClient => {
    let session: Session = window.localStorage.getItem('session');
    let acessToken = null;
    let refreshing = false; // Флаг, который будет отслеживать процесс обновления токена

    if (session) {
        session = JSON.parse(session);
        acessToken = session?.data?.access_token;
    }

    const client: ApiClient = {
        authProvider: 'keycloak',
        session: session,
        accessToken: acessToken,
        async getUser() {
            return {
                data: session?.data?.user
            };
        },

        async getSession() {
            if (!this.session) {
                throw new Error('No session found');
            }

            await this.refreshAccessToken();

            return this.session;
        },

        async setSession(accessToken: string, refreshToken: string) {
            this.session = { data: { access_token: accessToken, refresh_token: refreshToken } };
            return this.session;
        },

        async signOut() {
            console.log('Keycloak signOut');
            this.session = null;
            window.localStorage.removeItem('session');
        },
        async isAuthenticated() {
            const sessionExpired =
                this.session && this.session.data && isTokenExpired(this.session.data.access_token);
            return this.session !== null && !sessionExpired;
        },
        async refreshAccessToken() {
            // Если токен не истек или уже идет процесс обновления — не делаем ничего
            if (refreshing || !isTokenExpired(this.session?.data?.access_token)) {
                return;
            }

            if (!this.session?.data?.refresh_token) {
                throw new Error('No refresh token available');
            }

            refreshing = true; // Устанавливаем флаг, что процесс обновления начался

            try {
                const KEYCLOAK_URL = window.env.KEYCLOAK_URL;
                const response = await fetch(KEYCLOAK_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        grant_type: 'refresh_token',
                        client_id: 'itvectura-frontend',
                        refresh_token: this.session.data.refresh_token
                    })
                });

                if (!response.ok) {
                    console.error('Refresh token истек, нужен релогин');
                    window.localStorage.removeItem('session');
                    this.accessToken = null;
                    this.session = null;
                    return;
                }

                const data = await response.json();
                this.accessToken = data.access_token;
                this.session.data.access_token = data.access_token;
                this.session.data.refresh_token = data.refresh_token;

                // Сохраняем обновленную сессию в localStorage
                window.localStorage.setItem('session', JSON.stringify(this.session));
                console.debug('token is refreshed', data);
            } catch (error) {
                console.error('Error refreshing access token:', error);
            } finally {
                refreshing = false; // Сбрасываем флаг, что процесс обновления завершился
            }
        },

        async signIn(email: string, password: string) {
            console.debug('authKeycloak');
            const KEYCLOAK_URL = window.env.KEYCLOAK_URL;

            if (!KEYCLOAK_URL) {
                throw new Error('Keycloak URL not found');
            }

            const response = await fetch(KEYCLOAK_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    grant_type: 'password',
                    client_id: 'itvectura-frontend',
                    username: email,
                    password: password
                })
            });

            if (!response.ok) {
                throw new Error('Failed to authenticate with Keycloak');
            }

            const data: KeycloakTokenResponseType = await response.json();
            const decodedToken: KeycloakAccessTokenDecodeType = decodeJWT(data.access_token);
            const decodeObjectGuid = decodeObjectGUID(decodedToken.objectGUID);

            // Сохраняем данные авторизации Keycloak
            console.log('Keycloak token data ', data);
            const user = {
                id: decodeObjectGuid,
                name: decodedToken.name,
                email: email
            };
            this.session = {
                data: {
                    access_token: data.access_token,
                    refresh_token: data.refresh_token,
                    user
                }
            };
            this.accessToken = data.access_token;
            window.localStorage.setItem('session', JSON.stringify(this.session));
            return {
                data: {
                    user,
                    session: {
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        user
                    }
                },
                error: undefined
            };
        },

        storage: {
            // Пустая реализация для Keycloak
            listBuckets: async () => {
                throw new Error('Storage not supported for Keycloak');
            },
            uploadFile: async () => {
                throw new Error('Storage not supported for Keycloak');
            },
            downloadFile: async () => {
                throw new Error('Storage not supported for Keycloak');
            }
        }
    };

    return client;
};

export function isTokenExpired(token: string | undefined): boolean {
    if (!token) return false;
    const decodedToken = decodeJWT(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTime;
}
