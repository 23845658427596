import { IObjectWithId } from 'utils/store/MetaStore';

interface IMetaWithChildren extends IObjectWithId {
    children?: IObjectWithId[];
}

// Удаляем пустые массивы children
export function cleanEmptyChildren(items: IMetaWithChildren[]) {
    const cleanedItems = [...items];

    cleanedItems.forEach((item) => {
        if (item.children && item.children.length === 0) {
            delete item.children;
        } else if (item.children) {
            cleanEmptyChildren(item.children);
        }
    });

    return cleanedItems;
}

// TEST
export function checkAndMakeTreeData(
    data: IObjectWithId[],
    groupKeys: string[],
    parentField: string,
    viewFieldName: string
) {
    type NodesByIdType = IObjectWithId & { children: NodesByIdType[]; isHardGroup?: boolean };

    const nodesById: {
        [id: string]: NodesByIdType;
    } = {};
    let roots: NodesByIdType[] = [];

    if (!data) {
        return [];
    }

    // Шаг 1: Создаем словарь узлов по их Id и инициализируем поле children
    data.forEach((item) => {
        nodesById[item.Id] = { ...item, children: [] };
    });

    // Шаг 2: Строим иерархию на основе поля родителя
    data.forEach((item) => {
        const node = nodesById[item.Id];
        const parentInfo = item[parentField];

        if (parentInfo && parentInfo.Id) {
            const parentNode = nodesById[parentInfo.Id];
            if (parentNode) {
                parentNode.children.push(node);
            } else {
                // Родитель не найден, считаем узел корневым
                roots.push(node);
            }
        } else {
            // Нет родителя, узел корневой
            roots.push(node);
        }
    });

    // console.log(roots);

    // function calculateMaxDepth(nodes: NodesByIdType[]): number {
    //     let maxDepth = 0;
    //
    //     function traverse(node: NodesByIdType, currentDepth: number) {
    //         if (currentDepth > maxDepth) {
    //             maxDepth = currentDepth;
    //         }
    //         if (node.children && node.children.length > 0) {
    //             node.children.forEach((child) => {
    //                 traverse(child, currentDepth + 1);
    //             });
    //         }
    //     }
    //
    //     nodes.forEach((node) => {
    //         traverse(node, 1); // Начинаем с глубины 1
    //     });
    //
    //     return maxDepth;
    // }

    // const maxDepth = calculateMaxDepth(roots);
    // console.log('Максимальная глубина дерева:', maxDepth);

    // Шаг 3: Рекурсивно группируем детей по заданным ключам
    function groupChildren(node: NodesByIdType, depth: number) {
        // console.log(depth, node.children);
        if (!node.children || node.children.length === 0) {
            return;
        }

        if (groupKeys && depth < (parentField ? 1 : groupKeys.length)) {
            // const key = parentField ? groupKeys[0] : groupKeys[depth];
            // const key = groupKeys[depth];
            for (const groupKey of groupKeys) {
                node.children = groupBy(node.children, groupKey);
            }
            // console.log(groupBy(node.children, key));
            // node.children = groupBy(node.children, key);
        }

        node.children.forEach((child) => {
            groupChildren(child, depth + 1);
        });

        return node.children;
    }

    roots = groupChildren({ children: roots }, 0);

    // console.log(roots);

    // console.log(roots);
    // Начинаем с глубины 0
    // roots.forEach((root) => {
    //     groupChildren(root, 0);
    // });

    return cleanEmptyChildren(roots ?? []);

    // Вспомогательная функция для группировки по одному ключу
    function groupBy(items: NodesByIdType[], key: string) {
        const groups: { [groupKey: string]: NodesByIdType } = {};

        items.forEach((item) => {
            const groupValue = item[key];
            // const groupKey = JSON.stringify(groupValue);
            // const groupKey = groupValue?.Id ?? JSON.stringify(groupValue);
            const groupKey = groupValue?.Id ?? groupValue;
            // console.log(groupKey);

            if (groupKey) {
                if (!groups[groupKey]) {
                    // Создаем новый объект группы
                    // groupData[viewFieldName] =
                    //     item[viewFieldName] ??
                    //     item.ShortTitle ??
                    //     item.PluralName ??
                    //     item.Name ??
                    //     item.Key ??
                    //     item.Code;

                    // const isGroupTypeEqItemsType =
                    //     groupValue?.Type && item?.Type && groupValue?.Type?.Id === item?.Type?.Id;

                    groups[groupKey] = {
                        ...groupValue,
                        children: [],
                        [viewFieldName]:
                            groupValue.ShortTitle ??
                            groupValue.PluralName ??
                            groupValue.Name ??
                            groupValue.Key ??
                            groupValue.Code,
                        isHardGroup: true
                    } as NodesByIdType;
                }

                // console.log(groups[groupKey]);

                groups[groupKey].children.push(item);
            } else {
                groups[item.Id] = item;
            }
        });

        // Преобразуем группы в массив
        return Object.values(groups);
    }
}

// export const checkAndMakeTreeData = (
//     data: IObjectWithId[],
//     parentFieldName: string = 'Parent',
//     disableReconstructArray = true,
//     viewFieldName: string | undefined = undefined
// ): IMetaWithChildren[] => {
//     const elementsByKey: { [key: string | number]: IMetaWithChildren } = {};
//     const dataWithChildren = data;
//
//     // Создаем словарь для быстрого доступа к элементам по ключам
//     dataWithChildren.forEach((item) => {
//         elementsByKey[item.Id] = item;
//         runInAction(() => {
//             item.children = [];
//         });
//     });
//
//     const result: IMetaWithChildren[] = [];
//
//     // Проходимся по всем элементам массива
//     dataWithChildren.forEach((item) => {
//         // Проверяем, есть ли у элемента ParentId
//         if (item[parentFieldName]?.Id) {
//             const parent = elementsByKey[item[parentFieldName]?.Id];
//             // Если родитель присутсвует в общем массиве данных, то...
//             if (parent && parent.children) {
//                 // ...добавляем текущий элемент в массив children родителя
//                 parent.children.push(item);
//                 // Иначе будем отображать в корне
//             } else result.push(item);
//         } else {
//             // Если у элемента нет ParentId, добавляем его в корневой массив
//             result.push(item);
//         }
//     });
//
//     // Проверяем, совпадает ли выходной массив с исходным
//     if (!disableReconstructArray && result.length === data.length) {
//         // Вычленяем все уникальные элементы с parentFieldName
//         // const parentItemsSet = new Set<string>();
//         const parentItemsSet: IObjectWithId[] = [];
//         data.forEach((item) => {
//             if (item[parentFieldName]?.Id) {
//                 const { Name, Key, Code, PluralName, ShortTitle } = item[parentFieldName];
//
//                 // [viewFieldName]: ShortTitle || PluralName || Name || Key || Code
//                 const addable = viewFieldName
//                     ? // JSON.stringify(
//                       {
//                           ...item[parentFieldName],
//                           isHardGroup: true,
//                           [viewFieldName]: ShortTitle || PluralName || Name || Key || Code
//                       }
//                     : // )
//                       // JSON.stringify(
//                       {
//                           ...item[parentFieldName],
//                           isHardGroup: true
//                       };
//                 // );
//
//                 const hasItem = parentItemsSet.findIndex((i) => i.Id === addable.Id) !== -1;
//
//                 // console.log(addable.includes('System') ? addable : undefined);
//
//                 if (!hasItem) parentItemsSet.push(addable);
//
//                 // parentItemsSet = uniqWith([...parentItemsSet, addable], isEqual);
//                 // parentItemsSet.add(addable);
//             }
//         });
//         // const parentItems = JSON.parse(`[${Array.from(parentItemsSet).join(',')}]`);
//         const parentItems = parentItemsSet;
//
//         // Объединяем родительские элементы с остальными и снова выполняем преобразование
//         const combinedData = [...parentItems, ...data];
//
//         return checkAndMakeTreeData(combinedData, parentFieldName, true);
//     }
//
//     const finalResult = [];
//
//     // Сортируем детей если у них есть ChildIndex
//     result.forEach((itemWithChildren) => {
//         finalResult.push(
//             itemWithChildren.children?.slice()?.sort((a, b) => {
//                 if (a.ChildIndex && b.ChildIndex) {
//                     const indexA = a.ChildIndex as number;
//                     const indexB = b.ChildIndex as number;
//                     return indexA < indexB ? -1 : 1;
//                 }
//
//                 return 1;
//             })
//         );
//     });
//
//     return cleanEmptyChildren(result);
// };
