import { DownOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Flex, List, Modal, Tree, TreeDataNode } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Meta, MetaField } from 'modules/services/backend-api/generated_info';

import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { useList } from 'react-use';
import { FilterField } from '../../types';
import {
    getActiveFiltersFromFields,
    getDefaultFilterOperator,
    getInitialActiveFilters
} from '../../utils';
import { v4 } from 'uuid';
import { PlainObject } from '@gilbarbara/types';

interface AllFiltersModalProps {
    open: boolean;
    meta: string;
    setOpen: (open: boolean) => void;
    setActiveFilters: (filters: FilterField[]) => void;
    activeFilters: FilterField[];
    metaFields: MetaField[];
    metaChilds: Meta[];
    filtersFromLayout: FilterField[];
}

interface DataNode extends TreeDataNode {
    data: Meta | MetaField;
    parentName: string | null;
    children: DataNode[];
    parentId: React.Key | string | null;
    technicalName: string;
    parentTechnicalName: string | null;
}

// Функция для создания дерева из массивов fields и children
function buildTree(
    fields: MetaField[],
    children: Meta[],
    language: string,
    filtersFromLayout?: FilterField[]
) {
    // Вспомогательная функция для создания узла
    const createNode = (
        item: Meta | MetaField,
        parent: DataNode | MetaField | Meta | null = null
    ): DataNode => {
        const pluralName = (item as Meta).PluralName?.[language];
        const name = (item as MetaField).Name?.[language];
        const id = item.Id as string;

        let parentName = null;

        if (parent) {
            const parentPluralName = (parent as Meta)?.PluralName?.[language];
            const parentFieldName = (parent as MetaField)?.Name?.[language];
            const parentTitle = (parent as DataNode)?.title as string;

            parentName = parentTitle ?? parentPluralName ?? parentFieldName;
        }

        const isNoFilterIndex = !(item as MetaField).FilterIndex;
        const isFieldNotInLayoutsFilter = !filtersFromLayout?.find(
            ({ field: { FieldName } }) => FieldName === (item as MetaField).FieldName
        );

        return {
            title: pluralName ?? name,
            key: id,
            children: [] as DataNode[],
            data: item,
            technicalName: (item as Meta).ArrayNameInRoot ?? (item as MetaField).FieldName,
            parentTechnicalName: parent
                ? (parent as Meta).ArrayNameInRoot ??
                  (parent as MetaField).FieldName ??
                  (parent as DataNode).technicalName
                : null,
            parentId: (parent as DataNode)?.key ?? (parent as Meta | MetaField)?.Id ?? null,
            parentName,
            selectable: isNoFilterIndex && isFieldNotInLayoutsFilter
        };
    };

    // Формируем узлы первого уровня из `fields`
    const tree = fields.map((field) => createNode(field));

    // Функция для добавления дочерних узлов рекурсивно
    const addChildrenNodes = (parentNode: DataNode, childArray: MetaField[]) => {
        childArray.forEach((child) => {
            // Создаем узел для элемента child
            const childNode = createNode(child, parentNode);

            // // Рекурсивно добавляем дочерние узлы // TODO: может ли быть вложенность больше чем 2?
            // if (child.Fields && child.Fields.length > 0) {
            //     childNode.children = child.Fields.map((field) => createNode(field, child));
            // }
            parentNode.children.push(childNode);
        });
    };

    // Добавляем дочерние узлы к дереву
    children.forEach((child) => {
        const parentNode = createNode(child);
        addChildrenNodes(parentNode, child.Fields);
        tree.push(parentNode);
    });

    return tree;
}

// "Id":"36d1f823-ad70-e298-0db6-e30840d819f4", KEY
// "Id":"e4c8b04e-98f3-09d7-7a11-5c867545b4bf", TYPE

export const AllFiltersModal = memo<AllFiltersModalProps>(
    ({
        open,
        activeFilters: af,
        setActiveFilters: setAf,
        setOpen,
        meta,
        metaChilds,
        metaFields,
        filtersFromLayout
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        // const [activeData, setActiveData] = useState<FilterField[]>([]);
        const [activeFilters, activeFiltersMethods] = useList<DataNode & { filter: FilterField }>(
            []
        );
        // console.log('2', activeFilters);
        // const [actieFiltersKeys, setActiveFiltersKeys] = useState<React.Key[]>([]);
        const [actieFiltersKeys, activeFiltersKeysMethods] = useList<React.Key>([]);
        // console.log(actieFiltersKeys, activeFilters);

        const mappedAF = useMemo<DataNode[]>(
            () =>
                af.map((item) => {
                    const field = item.field;

                    const splitedDisplayTitle = field.DisplayTitle?.split('.');
                    const parentName =
                        splitedDisplayTitle?.length > 1 ? splitedDisplayTitle[1] : null;

                    const isNoFilterIndex = !field.FilterIndex;
                    const isFieldNotInLayoutsFilter = !filtersFromLayout.find(
                        ({ field: { FieldName } }) => FieldName === field.FieldName
                    );

                    return {
                        title: field.Name?.[language],
                        key: field.Id as React.Key,
                        children: [] as DataNode[],
                        data: field,
                        // parentId: (parent as DataNode)?.key ?? (parent as Meta | MetaField)?.Id ?? null,
                        parentName,
                        filter: item,
                        selectable: isNoFilterIndex && isFieldNotInLayoutsFilter,
                        parentId: null,
                        technicalName: field.FieldName,
                        parentTechnicalName: null
                    };
                }),
            [af, filtersFromLayout, language]
        );

        // console.log(mappedAF);

        useEffect(() => {
            activeFiltersMethods.set(mappedAF);
            activeFiltersKeysMethods.set(mappedAF.map((filter) => filter.key));
            // setActiveData(activeFilters);
        }, [mappedAF]);

        useEffect(() => {
            // console.log('INIT');
            const initialActiveFilters = getInitialActiveFilters(metaFields, filtersFromLayout);

            activeFiltersMethods.set((prev) => {
                if (prev.length > 0) {
                    return prev;
                }

                return initialActiveFilters;
            });
            // setActiveData((prev) => {
            //     if (prev.length > 0) {
            //         return prev;
            //     }

            //     return initialActiveFilters;
            // });

            setAf((prev) => {
                const isPrevEqFieldsFilter = isEqual(prev, getActiveFiltersFromFields(metaFields));
                const isPrevEqInitialFilter = isEqual(prev, initialActiveFilters);

                // console.log(prev, getActiveFiltersFromFields(metaFields), initialActiveFilters);

                if (prev.length === 0) return initialActiveFilters;
                if (isPrevEqFieldsFilter && !isPrevEqInitialFilter) return initialActiveFilters;
                if (isPrevEqInitialFilter) return initialActiveFilters;

                if (!isPrevEqInitialFilter && !isPrevEqFieldsFilter) return prev;
                return prev;
            });
            // setActiveFilters((prev) => {
            //     const isPrevEqFieldsFilter = isEqual(prev, getActiveFiltersFromFields(metaFields));
            //     const isPrevEqInitialFilter = isEqual(prev, initialActiveFilters);

            //     // console.log(prev, getActiveFiltersFromFields(metaFields), initialActiveFilters);

            //     if (prev.length === 0) return initialActiveFilters;
            //     if (isPrevEqFieldsFilter && !isPrevEqInitialFilter) return initialActiveFilters;
            //     if (isPrevEqInitialFilter) return initialActiveFilters;

            //     if (!isPrevEqInitialFilter && !isPrevEqFieldsFilter) return prev;
            //     return prev;
            // });
        }, [metaFields, filtersFromLayout]);
        // }, [metaFields, filtersFromLayout]);

        const allFilters = useMemo(() => {
            const treeItems = buildTree(metaFields, metaChilds, language, filtersFromLayout);

            // console.log(treeItems);

            return treeItems;

            // return treeItems.filter(
            //     (item) =>
            //         !activeFilters.find(
            //             (active) =>
            //                 `${active.title}_${active.parentName}` ===
            //                 `${item.title}_${item.parentName}`
            //         )
            // );

            // return metaFields.filter(
            //     (field) => !activeData.find((active) => field.FieldName === active.field.FieldName)
            // );
        }, [metaFields, metaChilds, language, filtersFromLayout]);
        // }, [metaFields, metaChilds, language, activeData]);

        const addAppliedField = useCallback((field: FilterField) => {
            activeFiltersMethods.push(field);
            // setActiveData((prevValue) => {
            //     return [...prevValue, field];
            // });
        }, []);

        // const removeAppliedField = useCallback((columnName: string) => {
        //     activeFiltersMethods.filter((node) => node.filter.field.ColumnName !== columnName);

        //     // setActiveData((prevValue) => {
        //     //     const copy = [...prevValue];
        //     //     const fieldIndex = copy.findIndex((field) => field.field.ColumnName === columnName);

        //     //     if (fieldIndex >= 0) {
        //     //         copy.splice(fieldIndex, 1);
        //     //     }

        //     //     return copy;
        //     // });
        // }, []);

        const handleCancel = () => {
            activeFiltersMethods.set(mappedAF);
            activeFiltersKeysMethods.set(mappedAF.map((filter) => filter.key));

            setOpen(false);
        };

        const handleOk = () => {
            setAf(
                activeFilters.map((activeFilter) => ({
                    field: {
                        ...activeFilter.filter.field,
                        isChild: !!activeFilter.parentName,
                        DisplayTitle: activeFilter.parentName
                            ? `${activeFilter.parentName}.${activeFilter.title}`
                            : activeFilter.title,
                        withAny: activeFilter.parentName
                            ? (filter: string) =>
                                  `${activeFilter.parentTechnicalName}=any(${filter})`
                            : (filter: string) => filter
                    },
                    values: activeFilter.filter.values
                }))
            );
            // setActiveFilters(activeData);
            setOpen(false);
        };

        // console.log(allFilters, activeData);

        return (
            <Modal
                open={open}
                onCancel={handleCancel}
                onOk={handleOk}
                okText={t('ok')}
                cancelText={t('cancel')}
                width="60%"
                destroyOnClose
                title={t('choose_fields')}
                centered
            >
                <Flex gap={5}>
                    <Card title={`${t('all_fields')}:`} style={{ width: '100%' }}>
                        <Tree
                            style={{ height: 'calc(100vh - 34rem)', overflow: 'auto' }}
                            showLine
                            switcherIcon={<DownOutlined />}
                            selectedKeys={actieFiltersKeys}
                            checkStrictly={true}
                            multiple={true}
                            onSelect={(selectedKeys, { selectedNodes }) => {
                                activeFiltersMethods.clear();

                                const notParentSelectedNodes = selectedNodes.filter(
                                    (node) => !node.children.length
                                );
                                const keys = notParentSelectedNodes.map((node) => node.key);

                                activeFiltersKeysMethods.set(keys);

                                for (const node of notParentSelectedNodes) {
                                    const nodeData = node.data as MetaField;
                                    const fieldId = nodeData.Id;
                                    // console.log(activeFilters, node);

                                    const alreadySelectedFilter = activeFilters.find(
                                        (activeFilter) => activeFilter.filter?.field?.Id === fieldId
                                    )?.filter;

                                    const filterField: FilterField = alreadySelectedFilter ?? {
                                        field: toJS(nodeData),
                                        values: []
                                    };

                                    if (!alreadySelectedFilter) {
                                        filterField.values.push({
                                            operator: getDefaultFilterOperator(filterField),
                                            column: nodeData.ColumnName
                                        });
                                    }

                                    addAppliedField({ ...node, filter: filterField });
                                }
                            }}
                            treeData={allFilters}
                        />
                        {/* <List
                            className="all_filters"
                            dataSource={allFilters || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        const filterField: FilterField = {
                                            field: item,
                                            values: []
                                        };

                                        filterField.values.push({
                                            operator: getDefaultFilterOperator(filterField),
                                            column: filterField.field.ColumnName
                                        });

                                        addAppliedField(filterField);
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Flex gap={15}>
                                        <PlusOutlined
                                            style={{
                                                cursor: 'pointer',
                                                color: '#3287d3'
                                            }}
                                        />
                                        {item.Name[language] || t(item.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List> */}
                    </Card>
                    {/* <Flex
                        style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.25)' }}
                        align="center"
                        justify="center"
                        vertical
                    >
                        <span>{'>'}</span>
                        <span>{'<'}</span>
                    </Flex>
                    <Card title={`${t('active_fields')}:`} style={{ width: '50%' }}>
                        <List
                            className="active_filters"
                            dataSource={activeData || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        removeAppliedField(item.field.ColumnName);
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Flex gap={15}>
                                        {!item.field.FilterIndex &&
                                        !filtersFromLayout.find(
                                            (fl) => fl.field.FieldName === item.field.FieldName
                                        ) ? (
                                            <MinusOutlined
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#fc5e53'
                                                }}
                                            />
                                        ) : undefined}
                                        {item.field.Name[language] || t(item.field.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List>
                    </Card> */}
                </Flex>
            </Modal>
        );
    }
);
