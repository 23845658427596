import { Layout } from 'antd';
import { Popup, SafeArea, TabBar } from 'antd-mobile';
import React, { memo, useCallback, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { useLocation } from 'react-router-dom';
import { useAliveController } from 'react-activation';
import { useBoolean } from 'react-use';
import { use100vh } from 'react-div-100vh';
import { Aside, CommonPage, Footer, Header } from '../DefaultLayout/components';
import { ID_REGX, USER_ID_REGX } from '../DefaultLayout/components/constants';
import { LazyIcon } from '../../../smart/ui';
import { useStoreNavigate } from '../../../utils/store';

interface MobileLayoutProps extends React.PropsWithChildren {
    user: User | null;
    setUser: (user: User | null) => void;
    numNotifications: number;
}

const tabs = [
    {
        key: 'menu',
        title: 'Меню',
        icon: <LazyIcon icon={'MenuOutlined'} />
        // badge: '5'
    },
    {
        key: 'home',
        title: 'Главная',
        icon: <LazyIcon icon={'HomeOutlined'} />
        // badge: Badge.dot
    },
    {
        key: 'chips',
        title: 'Вкладки',
        icon: <LazyIcon icon={'BarsOutlined'} />
        // badge: '5'
    }
];

export const MobileLayout = memo<MobileLayoutProps>(
    ({ children, user, setUser, numNotifications }) => {
        const { pathname, state: locationState } = useLocation();
        const { dropScope } = useAliveController();
        const navigate = useStoreNavigate();

        const height = use100vh();
        const hundredVh = height ? `${height}px` : '100vh';
        const pathnameLastElem = pathname.split('/').at(-1)?.trim() as string;
        const pathnameLastElemForDetail = pathname.split('/').at(-2)?.trim() as string;

        const isDetailPage =
            ID_REGX.test(pathnameLastElem) ||
            USER_ID_REGX.test(pathnameLastElem) ||
            pathnameLastElem === ':' ||
            pathnameLastElem === 'new';

        const isBookingSlotsPage = pathnameLastElem === 'resource-planner';

        const storedOpenState = sessionStorage.getItem('menuOpen');
        const [open, setOpen] = useState<boolean>(
            storedOpenState ? JSON.parse(storedOpenState) : false
        );
        const [chipsPopup, setChipsPopup] = useBoolean(false);

        const handleDropScope = useCallback(() => {
            dropScope(
                !isDetailPage
                    ? isBookingSlotsPage
                        ? `resourcePlanner_${locationState?.cacheKey || pathname}`
                        : `tablePage_${locationState?.cacheKey || pathname}`
                    : `detailPage_${locationState?.cacheKey || pathname}`
            );
        }, [dropScope, isDetailPage, isBookingSlotsPage, locationState?.cacheKey, pathname]);

        const handleAsideToggle = useCallback(() => {
            setOpen((prev) => {
                sessionStorage.setItem('menuOpen', JSON.stringify(!prev));
                return !prev;
            });
        }, []);

        const handleCloseAside = useCallback(() => {
            setOpen(false);
            sessionStorage.setItem('menuOpen', JSON.stringify(false));
        }, []);

        const tabActions = {
            home: () => navigate('/'),
            menu: handleAsideToggle,
            chips: () => setChipsPopup(true)
        };

        return (
            // <Layout style={{ minHeight: '100vh' }}>
            <Layout style={{ height: hundredVh, overflowY: 'auto' }}>
                <Aside isOpenStatus={open} onClose={handleCloseAside} />
                <div>
                    <SafeArea position="top" />
                    <Header
                        user={user}
                        setUser={(user) => {
                            // setOpen(false);
                            setUser(user);
                        }}
                        numNotifications={numNotifications}
                        // style={{
                        //     marginLeft: calcMarginLeftByScreenSize(),
                        //     minWidth: `calc(100vw - ${calcMarginLeftByScreenSize()}px)`
                        // }}
                        // breadcrumbs={<Breadcrumbs />}
                        // toggleMenuButton={
                        //     <Button
                        //         onClick={handleAsideToggle}
                        //         icon={open ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                        //         // icon={open ? <LayoutFilled /> : <LayoutOutlined />}
                        //         type="text"
                        //     />
                        // }
                    />
                </div>
                <Layout.Content
                    style={{
                        // marginLeft: calcMarginLeftByScreenSize(),
                        overflow: 'auto',
                        maxHeight: `calc(${hundredVh} - 40px - 50px)`
                    }}
                    className="default-layout__content"
                >
                    <CommonPage
                        dropScope={handleDropScope}
                        isDetailPage={isDetailPage}
                        pathnameLastElem={pathnameLastElem}
                        pathnameLastElemForDetail={pathnameLastElemForDetail}
                    >
                        {children}
                    </CommonPage>
                </Layout.Content>
                <div>
                    <Popup
                        position={'right'}
                        visible={chipsPopup}
                        onMaskClick={() => setChipsPopup(false)}
                    >
                        <Footer />
                    </Popup>
                    <TabBar
                        activeKey={pathname === '/' ? 'home' : ''}
                        safeArea
                        onChange={(v) => tabActions[v]()}
                        style={{ backgroundColor: 'white' }}
                    >
                        {tabs.map((item) => (
                            <TabBar.Item
                                key={item.key}
                                icon={item.icon}
                                title={item.title}
                                badge={item.badge}
                            />
                        ))}
                    </TabBar>
                </div>
            </Layout>
        );
    }
);
