import loadable from '@loadable/component';
import { App as AntdApp, Flex } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { AliveScope } from 'react-activation';
import { I18nextProvider, initReactI18next, useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { createGlobalState, useBoolean, useEffectOnce, useMedia } from 'react-use';
import { UniversalBoundary } from 'components';
import { Layout } from 'modules';
import { META } from 'modules/services/backend-api/generated_types';
// import { KeepAlivedSmartDetailPage, KeepAlivedSmartTablePage } from 'smart/pages';
import { ILogMessagesModal, LogMessagesModal } from 'smart/components';
import { SkeletonLoader } from 'ui/Skeleton/Skeleton';
import { routeStore } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { useStoreNavigate } from 'utils/store/utils/hooks/useStoreNavigate';

import { languagesStore } from 'utils/store/languages/languagesStore';
import { allRoutes as routes } from '../routing/routes';
import { Loader } from '../ui/Loader/Loader';
import { i18n } from '../utils/i18n/i18n';
import { DEFAULT_LANG } from './constants';

import { AppProvidersWrapper } from './contexts';
import '../scss/style.scss';
import { supabaseStorageKey } from '../modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { NotificationResponse } from '../modules/services/backend-api/generated_api';
import { useAuthUser } from 'modules/auth/hooks/useAuthUser';

const LoginPage = lazy(() =>
    import('pages/LoginPage').then((module) => ({ default: module.LoginPage }))
);
const SignupPage = lazy(() =>
    import('pages/SignupPage').then((module) => ({ default: module.SignupPage }))
);

const InvitePage = lazy(() =>
    import('pages/InvitePage').then((module) => ({ default: module.InvitePage }))
);

const PublicOffertPage = lazy(() =>
    import('pages/public-offert/PublicOffertPage').then((module) => ({
        default: module.PublicOffertPage
    }))
);
const VerifyPage = lazy(() =>
    import('pages/VerifyPage').then((module) => ({ default: module.VerifyPage }))
);
lazy(() =>
    import('pages/ChangePasswordPage').then((module) => ({ default: module.ChangePasswordPage }))
);
const KeepAlivedSmartDetailPage = lazy(() =>
    import('smart/pages/KeepAlivedSmartDetailPage').then((module) => ({
        default: module.KeepAlivedSmartDetailPage
    }))
);
lazy(() =>
    import('smart/pages/KeepAlivedSmartOverviewPage').then((module) => ({
        default: module.KeepAlivedSmartOverviewPage
    }))
);
const KeepAlivedSmartTablePage = lazy(() =>
    import('smart/pages/KeepAlivedSmartTablePage').then((module) => ({
        default: module.KeepAlivedSmartTablePage
    }))
);

const KeepAlivedTarifCalculatorPage = lazy(() =>
    import('pages/tariff-calculator/TariffCalculatorPage').then((module) => ({
        default: module.KeepAlivedTariffCalculatorPage
    }))
);

const KeepAlivedComparingPage = lazy(() =>
    import('pages/ComparingPage').then((module) => ({
        default: module.KeepAlivedComparingPage
    }))
);

const KeepAlivedMapPage = lazy(() =>
    import('pages/maps/MapPage').then((module) => ({
        default: module.KeepAlivedMapPage
    }))
);

lazy(() =>
    import('pages/tms-reports/margin-orders/MarginOrders_Page').then((module) => ({
        default: module.MarginOrders_Page
    }))
);

const BookingSlotsPage = lazy(() =>
    import('smart/pages/BookingSlotsPage').then((module) => ({
        default: module.BookingSlotsPage
    }))
);

const CalendarTimeline = lazy(() =>
    import('smart/modules').then((module) => ({ default: module.CalendarTimeline }))
);

const GanttDiagramPage = lazy(() =>
    import('smart/pages').then((module) => ({ default: module.GanttDiagramPage }))
);

const KeepAlivedModifiedSmartTimelinePage = lazy(() =>
    import('smart/pages/KeepAlivedModifiedSmartTimelinePage').then((module) => ({
        default: module.KeepAlivedModifiedSmartTimelinePage
    }))
);

lazy(() => import('pages/SupersetPage').then((module) => ({ default: module.SupersetPage })));
lazy(() => import('pages/reports/ReportPage').then((module) => ({ default: module.ReportPage })));

const INITIAL_TITLE = 'IT Vectura | Logistics IT software';

export const useLogsModal = createGlobalState<ILogMessagesModal & { open: boolean }>({
    open: false,
    logs: { log_messages: [] }
});

export const App: React.FC = observer(() => {
    const lastRoute = toJS(routeStore.routes.at(-1));
    // console.log(lastRoute);
    const sbAuthToken = window.localStorage.getItem(supabaseStorageKey);

    // console.log(toJS(metaStore.meta.get('all')));

    const { t } = useTranslation();
    const { user, loading, setUser, provider } = useAuthUser();
    const navigate = useStoreNavigate();
    const { pathname, search, state } = useLocation();
    const [logsModalState] = useLogsModal();
    const isBigMobile = useMedia('(max-width: 480px)');

    // const [sbAuthToken] = useLocalStorage<{ access_token: string }>('sb-studio-auth-token');
    const [isMetaLoading, setIsMetaLoading] = useBoolean(true);
    const [isError, setIsError] = useBoolean(false);
    const [numNotifications, setNumNotifications] = useState(0);
    // console.log(user, sbAuthToken);

    useEffectOnce(() => {
        document.documentElement.lang = i18n.language || DEFAULT_LANG;
        document.title = INITIAL_TITLE;

        // console.log(pathname, search, state);

        routeStore.push({
            pathname,
            search: '',
            state:
                state || search
                    ? { ...state, filterString: search?.slice(1) || state?.filterString }
                    : null
        });

        navigate(
            {
                pathname,
                search: ''
            },
            {
                state:
                    state || search
                        ? { ...state, filterString: search?.slice(1) || state?.filterString }
                        : null
            }
        );

        // routeStore.push({
        //     pathname,
        //     search: '',
        //     state: { ...state, filterString: search || state?.filterString }
        // });

        (async () => {
            const isTolgeeDisabled = await metaStore.getParam({
                param_name: 'DISABLE_TOLGEE'
            });

            if (isTolgeeDisabled) {
                // disable tolgee
                console.log('[i18n] disabled');
                await i18n.use(initReactI18next).init({
                    resources: {
                        ru: {
                            translations: '{}'
                        },
                        kk: {
                            translations: '{}'
                        },
                        en: {
                            translations: '{}'
                        },
                        az: {
                            translations: '{}'
                        },
                        tr: {
                            translations: '{}'
                        },
                        tech: {
                            translations: JSON.stringify({})
                        }
                    }
                });
            }
        })();
    });

    useEffect(() => {
        if (!metaStore.api?.channel.isConnected || !user) {
            setNumNotifications(0);
            return;
        }

        const subscribe = async () => {
            const unsubscribe = await metaStore.subscribeNotifications(
                (notification: NotificationResponse) => {
                    setNumNotifications(notification.num_unread);
                }
            );

            return () => {
                setNumNotifications(0);

                if (unsubscribe) {
                    unsubscribe();
                }
            };
        };

        const cleanup = subscribe();

        return () => {
            cleanup.then((unsubscribe) => {
                if (unsubscribe) {
                    unsubscribe();
                }
            });
        };
    }, [metaStore.api?.channel.isConnected, user]);

    useEffect(() => {
        if (window.api) languagesStore.fetch();
    }, [window.api]); //убрал sbAuthToken из зависимостей

    useEffect(() => {
        (async () => {
            try {
                if (user) {
                    // identfy with email, of not defined then id
                    posthog.identify(user.email || user.id);

                    setIsMetaLoading(true);
                    // routeStore.clear();

                    await metaStore.getRoutes();
                    await Promise.all([
                        metaStore.getMenu(),
                        metaStore.makeSelect({ meta: 'InfoMeta' }),
                        metaStore.getInfo('DocOrders'),
                        metaStore.getInfo('DocTrips'),
                        metaStore.getInfo('InfoObjectProperties'),
                        metaStore.getInfo('InfoRequests')
                    ]);
                }
                setIsMetaLoading(false);
            } catch (error) {
                console.error(error);
                setIsError(true);
            }
        })();
    }, [user]);

    if (!loading && !user) {
        return (
            <I18nextProvider i18n={i18n}>
                <AntdApp>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            <Route path="/verify" element={<VerifyPage />} />
                            <Route
                                path="/login"
                                element={<LoginPage setUser={setUser} provider={provider} />}
                            />
                            <Route path="/signup" element={<SignupPage setUser={setUser} />} />
                            <Route path="/invited" element={<InvitePage setUser={setUser} />} />
                            <Route
                                path="/offer"
                                element={
                                    <PublicOffertPage
                                        open={pathname.includes('/offer')}
                                        setOpen={() => navigate(-1)}
                                    />
                                }
                            />
                            <Route path="*" element={<Navigate to={`/login`} />} />
                        </Routes>
                    </Suspense>
                </AntdApp>
            </I18nextProvider>
        );
    }

    if (loading || isMetaLoading) return <Loader />;

    if (isError)
        return (
            <Flex justify="center" align="center" style={{ width: '100vw', height: '100vh' }}>
                <UniversalBoundary
                    status="error"
                    title={`${t('something_goes_wrong')}...`}
                    subTitle={`${t('please_try_again')}`}
                    enableTryAgain
                    enableGoBackOrGoHome={false}
                    onTryAgain={() => window.location.reload()}
                />
            </Flex>
        );

    const metaRoutes = [...(toJS(metaStore.meta.get('all')?.routes) || [])];
    // console.log(metaRoutes.filter((r) => r.path?.includes('gantt')));
    // console.log(
    //     '[App] meta routes: ',
    //     metaRoutes.filter((l) => !!l.layout)
    // );
    const L = isBigMobile ? Layout.MobileLayout : Layout.DefaultLayout;

    return (
        // <AppProvidersWrapper i18n={i18n} user={user}>
        <AppProvidersWrapper i18n={i18n}>
            <AliveScope>
                <AntdApp>
                    <L user={user} setUser={setUser} numNotifications={numNotifications}>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="/verify" element={<Navigate to="/" />} />
                                <Route
                                    path="/login"
                                    element={
                                        <Navigate
                                            // to="/"
                                            to={{
                                                pathname: lastRoute?.pathname,
                                                search: lastRoute?.search
                                            }}
                                            state={lastRoute?.state}
                                        />
                                    }
                                />
                                <Route
                                    path="/signup"
                                    element={
                                        <Navigate
                                            // to="/"
                                            to={{
                                                pathname: lastRoute?.pathname,
                                                search: lastRoute?.search
                                            }}
                                            state={lastRoute?.state}
                                        />
                                    }
                                />
                                <Route
                                    path="/offer"
                                    element={
                                        <PublicOffertPage
                                            open={pathname.includes('/offer')}
                                            setOpen={() => navigate(-1)}
                                        />
                                    }
                                />

                                <Route
                                    path="/other/:meta"
                                    element={<KeepAlivedSmartTablePage meta={''} />}
                                />

                                <Route
                                    path="/other/:meta/:id"
                                    element={<KeepAlivedSmartDetailPage meta={''} />}
                                />

                                <Route path={'/calendar-timeline'} element={<CalendarTimeline />} />

                                {routes.map((route) => (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={
                                            // <RequirePermissions path={route.path}>
                                            <route.component />
                                            // </RequirePermissions>
                                        }
                                    />
                                ))}

                                {metaRoutes
                                    .filter(
                                        (metaRoute) =>
                                            !routes.find((route) => route.path === metaRoute.path)
                                    )
                                    .map((route) => ({
                                        ...route,
                                        path: route.path.split('?')[0]
                                    }))
                                    .map((metaRoute) => {
                                        // console.log('metaRoute:', metaRoute);
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedModifiedSmartTimelinePage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <KeepAlivedModifiedSmartTimelinePage />
                                                    }
                                                />
                                            );
                                        }
                                        if (metaRoute.component?.includes('BookingSlotsPage')) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<BookingSlotsPage />}
                                                />
                                            );
                                        }
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedSmartTablePage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <KeepAlivedSmartTablePage
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedSmartDetailPage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <KeepAlivedSmartDetailPage
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedTarifCalculatorPage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<KeepAlivedTarifCalculatorPage />}
                                                />
                                            );
                                        }

                                        if (
                                            metaRoute.component?.includes('KeepAlivedComparingPage')
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<KeepAlivedComparingPage />}
                                                />
                                            );
                                        }

                                        if (metaRoute.component?.includes('Gantt')) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <GanttDiagramPage
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }
                                        if (metaRoute.component?.includes('MapPage')) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<KeepAlivedMapPage />}
                                                />
                                            );
                                        }

                                        if (metaRoute.component) {
                                            const fallback = <SkeletonLoader />;
                                            const componentPath =
                                                metaRoute.component[0] !== '/'
                                                    ? `../${metaRoute.component}`
                                                    : `..${metaRoute.component}`;

                                            const componentName = metaRoute.component
                                                .split('/')
                                                .at(-1);

                                            const resolveComponent = (components: any) => {
                                                if (componentName) {
                                                    return components[componentName?.trim()];
                                                }
                                                return fallback;
                                            };

                                            const cacheKey = () => metaRoute.component;

                                            const prodImportCb = () =>
                                                import(
                                                    /* @vite-ignore */ `/assets/${componentName}.js`
                                                );

                                            const devImportCb = () =>
                                                import(/* @vite-ignore */ `${componentPath}`);

                                            const DynamicComponent = loadable<{ meta: META }>(
                                                import.meta.env.MODE === 'development'
                                                    ? devImportCb
                                                    : prodImportCb,
                                                {
                                                    fallback,
                                                    resolveComponent,
                                                    cacheKey
                                                }
                                            );

                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <DynamicComponent
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }

                                        // ### DEFAULT ###
                                        const potencialDetailPath = `${metaRoute.path}/:id`;
                                        const hasDetailInMeta = !!metaRoutes.find((route) =>
                                            route.path.includes(potencialDetailPath)
                                        );

                                        if (!hasDetailInMeta) {
                                            return (
                                                <React.Fragment key="hasDetailInMeta">
                                                    <Route
                                                        key={metaRoute.path}
                                                        path={metaRoute.path}
                                                        element={
                                                            <KeepAlivedSmartTablePage
                                                                meta={metaRoute.meta || ''}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        key={potencialDetailPath}
                                                        path={potencialDetailPath}
                                                        element={
                                                            <KeepAlivedSmartDetailPage
                                                                meta={metaRoute.meta || ''}
                                                            />
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }

                                        return (
                                            <Route
                                                key={metaRoute.path}
                                                path={metaRoute.path}
                                                element={
                                                    <KeepAlivedSmartTablePage
                                                        meta={metaRoute.meta || ''}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                            </Routes>
                        </Suspense>

                        <LogMessagesModal
                            open={logsModalState.open}
                            logs={logsModalState.logs}
                            onCancel={logsModalState.onCancel}
                            hardDuration={logsModalState.hardDuration}
                        />
                    </L>
                </AntdApp>
            </AliveScope>
        </AppProvidersWrapper>
    );
});
