import { client } from 'modules/supabase/contexts/SupabaseContext/ApiContext';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';

export const getFileUrlForDownload = async (bucket: string, path: string) => {
    // Скачиваем файл из хранилища
    const { data, error } = await client.storage.downloadFile(bucket, path);
    // const { data, error } = await supabaseClient.storage.from(bucket).download(path);

    // Если ошибка прилетела, то бросаем исключение
    if (error) throw error;

    // Создаем ссылку на скачивание файла
    return URL.createObjectURL(data);
};
