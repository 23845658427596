import { useState, useEffect, useRef } from 'react';
import { UseAuthUser } from './useAuthUser';
import { default as Keycloak } from 'keycloak-js';
import { useEffectOnce } from 'react-use';
import { client } from 'modules/supabase/contexts/SupabaseContext/ApiContext';
import { User } from 'modules/supabase/contexts/SupabaseContext/types';
export const keycloak = new Keycloak({
    url: 'https://keycloak.exp2.itvectura.com/auth', //https://keycloak.exp2.itvectura.com/auth/realms/itvectura/protocol/openid-connect/token //https://keycloak.exp2.itvectura.com/auth/
    realm: 'itvectura', //master //itvectura
    clientId: 'itvectura-frontend'
});
export const useKeycloakUser = (): UseAuthUser => {
    const provider = 'keycloak';
    const [user, setUser] = useState<User['data'] | null>(null);
    const [loading, setLoading] = useState(true);
    const [isPowerUser, setIsPowerUser] = useState(false);

    useEffectOnce(() => {
        const fetchUser = async () => {
            try {
                const { data: clientUser, error } = await client.getUser();
                if (error) throw error;
                setUser(clientUser);
            } catch (error) {
                console.error('Ошибка при получении пользователя Keycloak:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    });

    return { user, loading, isPowerUser, setUser, provider };
};

//TODO пробовать доставать токен из кеша
// useEffectOnce(() => {
//     const initKeycloak = async () => {
//         try {
//             // Попытка проверить существующую сессию
//             const authenticated = await keycloak.init();

//             if (authenticated) {
//                 // Если аутентификация прошла успешно, обновляем состояние
//                 setUser(keycloak);
//                 const roles = keycloak.tokenParsed?.realm_access?.roles || [];
//                 setIsPowerUser(roles.includes('Admin'));
//             } else {
//                 // Если не аутентифицирован, можем настроить логику для авторизации
//                 console.log('Пользователь не аутентифицирован. Необходима авторизация.');
//             }
//         } catch (error) {
//             console.error('Ошибка при инициализации Keycloak:', error);
//         } finally {
// setLoading(false);
//         }
//     };
//     const hashParams = new URLSearchParams(window.location.hash.replace('#', '?'));
//     const state = hashParams.get('state');
//     if (state == null) {
//         initKeycloak();
//     }
// });
