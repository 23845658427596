import { isEmpty } from 'is-lite/exports';
import dayjs from 'dayjs';
import { DayDataType } from './types';
import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from '../../../../modules/services/backend-api/generated_models';
import { IObjectWithId } from '../../../../utils/store/MetaStore';
import { HOURS } from '../constants';

type GetDataFunction = (options: {
    daysCount: number;
    freeSlots: RegLocationResourceSlot[];
    bookedSlots: RegBookedLocationSlot[];
    selectedResources: IObjectWithId[];
    selectedLocations: IObjectWithId[];
    selectedTrips: IObjectWithId[];
    language: string;
    firstColumnFieldName: string;
    // selectedDate: dayjs.Dayjs;
    // fetchBookedSlots: () => void;
}) => DayDataType[];

export const getData: GetDataFunction = ({
    freeSlots,
    language,
    bookedSlots,
    selectedTrips,
    firstColumnFieldName
}) => {
    const data: DayDataType[] = [];
    const selectedTripIds = selectedTrips.map((t) => t.Id);

    for (const slot of freeSlots) {
        if (!slot[firstColumnFieldName]) continue;

        if (data.find((d) => d.Id === slot[firstColumnFieldName]?.Id)) continue; // не будем дублировать ресурсы

        if (
            !isEmpty(selectedTrips) &&
            !bookedSlots.find(
                (bs) =>
                    bs[firstColumnFieldName]?.Id === slot[firstColumnFieldName]?.Id &&
                    selectedTripIds.includes(bs?.Trip?.Id ?? '')
            )
        )
            continue;

        const trips = Array.from(
            new Map(
                (
                    bookedSlots
                        .filter(
                            (bs) =>
                                bs[firstColumnFieldName]?.Id === slot[firstColumnFieldName]?.Id &&
                                bs.Trip
                        )
                        .map(
                            (bs) =>
                                ({
                                    ...bs.Trip,
                                    Date: bs.Date,
                                    SlotIndex: bs.SlotIndex
                                }) as IObjectWithId
                        ) ?? []
                ).map((obj) => [`${obj.Id}`, obj])
            ).values()
        );

        const downtimeEvents = Array.from(
            new Map(
                (
                    bookedSlots
                        .filter(
                            (bs) =>
                                bs[firstColumnFieldName]?.Id === slot[firstColumnFieldName]?.Id &&
                                bs.DowntimeEvent
                        )
                        .map(
                            (bs) =>
                                ({
                                    ...bs.DowntimeEvent,
                                    Date: bs.Date,
                                    SlotIndex: bs.SlotIndex
                                }) as IObjectWithId
                        ) ?? []
                ).map((obj) => [`${obj.Id}`, obj])
            ).values()
        );

        const CapacityCount = slot[firstColumnFieldName]?.CapacityCount ?? 1;
        const Id = slot[firstColumnFieldName]?.Id ?? '';
        const Name =
            slot[firstColumnFieldName]?.Name?.[language] ??
            slot[firstColumnFieldName]?.Key ??
            slot[firstColumnFieldName]?.Id;

        const finalChildren = [
            ...trips.map((t, index) => {
                return {
                    Key: t.Key,
                    Name: t?.Name?.[language] ?? t?.Key ?? t?.Id,
                    Id: t?.Id ?? '',
                    Trip: t,
                    TripId: t?.Id ?? '',
                    TripIndex: index,
                    ItemId: Id,
                    Meta: 'DocTrips',
                    CapacityCount,
                    Date: t.Date,
                    SlotIndex: t.SlotIndex,
                    ...HOURS
                };
            }),
            ...downtimeEvents.map((de, index) => {
                return {
                    Key: de.Key,
                    Name: de?.Name?.[language] ?? de?.Key ?? de?.Id,
                    Id: de?.Id ?? '',
                    DowntimeEvent: de,
                    DowntimeEventId: de?.Id ?? '',
                    DowntimeEventIndex: index,
                    ItemId: Id,
                    Meta: 'DocDowntimeEvents',
                    CapacityCount,
                    Date: de.Date,
                    SlotIndex: de.SlotIndex,
                    ...HOURS
                };
            })
        ].sort((a, b) => {
            if (a.Date && b.Date) {
                const dateComparison = dayjs(a.Date).diff(dayjs(b.Date));
                if (dateComparison !== 0) {
                    return dateComparison; // Если даты разные, возвращаем разницу
                }
            }
            // Если даты одинаковые, сравниваем по SlotIndex
            return (a.SlotIndex ?? 0) - (b.SlotIndex ?? 0);
        });

        // console.log(trips, downtimeEvents, finalChildren);

        const dataItem: DayDataType = {
            Key: slot[firstColumnFieldName]?.Key,
            Name,
            Id,
            Item: slot[firstColumnFieldName],
            ItemId: Id,
            TripId: undefined,
            CapacityCount,
            TripsIds: trips.map((t) => t?.Id),
            DowntimeEventsIds: trips.map((t) => t?.Id),
            Trips: trips,
            DowntimeEvents: trips,
            Meta: 'CatLocationResources', // TODO: либо убрать, либо прокинуть пропс meta
            ...HOURS,

            children: trips.length || downtimeEvents.length ? finalChildren : undefined
        };

        data.push(dataItem);
    }

    // console.log(freeSlots, data, bookedSlots);
    // console.log(data);

    return data; // TODO: sort
    // return data.sort((a, b) => (a.key > b.key ? 1 : -1));
};
