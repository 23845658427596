import { SaveOutlined } from '@ant-design/icons';
import { Flex, Button, Tooltip } from 'antd';
import { MultilanguageValueType } from 'components/fields';
import { useRef, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createGlobalState, useAsync } from 'react-use';
import { useLocation } from 'react-router-dom';

import { SmartMultilanguageField, SmartSelectField } from 'smart/components';
import { FieldsBox } from 'smart/modules/SmartDetailPage/ui';
import { fnv1aHash, JSONSafeParse } from 'smart/utils';
import { useNotifications } from 'utils/hooks';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { useUserData } from 'modules/supabase/utils/hooks/useSupabaseUser';

import { FilterField } from '../../types';

const useSelectedQueriesByLocation = createGlobalState(
    new Map<number, IObjectWithId | undefined>()
);

export const useSelectedQueries = (): [
    IObjectWithId | undefined,
    (newQuery: IObjectWithId | undefined) => void
] => {
    const location = useLocation();

    const [selectedQueryMap, setSelectedQueryMap] = useSelectedQueriesByLocation();

    const queryHashKey = useMemo(
        () => fnv1aHash(`${location.pathname}_${JSON.stringify(location.state ?? {})}`),
        [location.pathname, location.state]
    );

    const selectedQuery = useMemo(
        () => selectedQueryMap.get(queryHashKey),
        [queryHashKey, selectedQueryMap]
    );

    const setSelectedQuery = useCallback(
        (newQuery: IObjectWithId | undefined) => {
            const map = new Map(selectedQueryMap);
            map.set(queryHashKey, newQuery);

            setSelectedQueryMap(map);
        },
        [queryHashKey, selectedQueryMap]
    );

    return [selectedQuery, setSelectedQuery];
};

export const SavedQueryControl = ({
    filterString,
    onChangeFilterString,
    filters,
    onChangeFilters,
    meta
}: {
    filterString: string;
    onChangeFilterString: (newFilterString: string | undefined) => void;
    filters: FilterField[];
    onChangeFilters: (newFilters: FilterField[]) => void;
    meta: string;
}) => {
    const { pathname, state } = useLocation();
    const { t } = useTranslation();
    const { modal } = useNotifications();
    const { data, isPowerUser, loading } = useUserData();

    const userId = data?.Id;

    const navItem = useAsync(
        async () =>
            (
                await metaStore.makeSelect({
                    meta: 'InfoNavItems',
                    filters: `Path=eq.${`${pathname}${
                        state?.filterString ? `?${state?.filterString}` : ''
                    }`}`
                })
            )?.objects?.[0],
        []
    );

    // console.log(navItem);

    const newQueryName = useRef<MultilanguageValueType>({ ru: '' });

    const [selectedQuery, setSelectedQuery] = useSelectedQueries();
    const selectedQueryFilters = selectedQuery?.FilterData;
    const selectedQueryFilterString = selectedQuery?.Filter;

    // console.log(selectedQueryFilterString, selectedQueryFilters);

    const isEditedQuery =
        selectedQuery &&
        (selectedQuery?.User?.Id === userId || (selectedQuery?.IsPublic && isPowerUser)) &&
        JSON.stringify(filters) !== JSON.stringify(selectedQueryFilters);
    const isNewQuery = !selectedQuery && filterString;

    const handleSaveQuery = () => {
        modal.confirm({
            title: t('fill_for_save'),
            content: (
                <FieldsBox
                    labelWidth={105}
                    items={[
                        {
                            label: t('name'),
                            children: (
                                <SmartMultilanguageField
                                    value={undefined as unknown as MultilanguageValueType}
                                    onChange={(v) => {
                                        newQueryName.current = v;
                                    }}
                                />
                            )
                        }
                    ]}
                    size={'small'}
                />
            ),
            centered: true,
            destroyOnClose: true,
            okText: t('save'),
            cancelText: t('cancel'),
            onOk: async () => {
                const objectToSave = {
                    Filter: filterString,
                    FilterData: filters,
                    isActive: true,
                    Name: newQueryName.current,
                    Meta: { Code: meta },
                    NavItem: navItem.value ? { Id: navItem.value.Id } : undefined
                };

                if (data) objectToSave.User = data;

                const payload = {
                    meta: 'InfoSavedQueries',
                    objects: [objectToSave]
                };

                const save = await metaStore.makeSave(payload);
                const query = save?.objects?.[0] as IObjectWithId;

                setSelectedQuery(query);
            }
        });
    };

    const handleReSaveQuery = async () => {
        const objectToSave = {
            ...selectedQuery,
            Filter: filterString,
            FilterData: filters
        };

        const payload = {
            meta: 'InfoSavedQueries',
            objects: [objectToSave]
        };

        const save = await metaStore.makeSave(payload);
        const query = save?.objects?.[0] as IObjectWithId;

        setSelectedQuery(query);
    };

    useEffect(() => {
        if (selectedQuery) {
            onChangeFilterString(selectedQueryFilterString);
            onChangeFilters((JSONSafeParse(selectedQueryFilters) as FilterField[]) ?? filters);
        }
    }, [selectedQuery]);

    const buttonForSaveNew = (
        <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleSaveQuery}
            loading={loading || navItem.loading}
            className="btn-blue"
        >
            {t('save_query')}
        </Button>
    );

    const buttonForEditCurrent = (
        <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleReSaveQuery}
            loading={loading || navItem.loading}
            className="btn-blue"
        >
            {t('re_save_query')}
        </Button>
    );

    const button = isNewQuery ? buttonForSaveNew : isEditedQuery ? buttonForEditCurrent : null;

    // const inputControl = (
    //     <Flex gap={5}>
    //         <StringField readOnly value={filterString} onChange={onChangeFilterString} allowClear />
    //         {button}
    //     </Flex>
    // );

    const queryFilter = `Meta=eq.${meta}${
        navItem.value ? `&or(NavItem=eq.${navItem.value.Id},NavItem=is.null)` : '&NavItem=is.null'
    }${userId ? `&or(User=eq.${userId},IsPublic=eq.true)` : '&IsPublic=eq.true'}`;

    // console.log(userId);

    const selectControl = (
        <Flex gap={5}>
            <SmartSelectField
                value={selectedQuery}
                meta={'InfoSavedQueries'}
                navigateMetaOptions={{
                    metaName: 'InfoSavedQueries'
                }}
                filters={queryFilter}
                loading={loading}
                disabled={loading}
                onChange={setSelectedQuery}
            />
            {button}
        </Flex>
    );

    // const content = isNewQuery ? inputControl : selectControl;
    const content = selectControl;

    return (
        <Tooltip
            title={filterString}
            getPopupContainer={() => document.getElementById('table_filter_menu') as HTMLElement}
            overlayStyle={{ maxWidth: '90%' }}
        >
            <Flex vertical gap={5}>
                <span style={{ color: 'gray' }}>{t('saved_query_id')}:</span>
                {content}
            </Flex>
        </Tooltip>
    );
};
