import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

export const transformSlots = (
    intervals: PlainObject[],
    propertyNames: { StartDateAt: string; EndDateAt: string } = {
        StartDateAt: 'StartDateAt',
        EndDateAt: 'EndDateAt'
    }
) => {
    const slots: PlainObject[] = [];

    // console.log('I', intervals);

    intervals.forEach((interval) => {
        const start = dayjs(
            (interval.StartAt as string) ?? (interval[propertyNames.StartDateAt] as string)
        );
        const startMinutes = start.get('minutes');
        const startPercent = ((60 - startMinutes) / 60) * 100;
        const end = dayjs(
            (interval.EndAt as string) ?? (interval[propertyNames.EndDateAt] as string)
        );
        const endMinutes = end.get('minutes');
        const endPercent = ((endMinutes || 60) / 60) * 100;

        const startHour = start.get('hours');
        const endHour = end.get('hours') || 24;

        // console.log(start, end, startHour, endHour);

        for (let hour = startHour; hour < endHour; hour++) {
            const isStartHour = hour === startHour;
            const isEndHour = hour + 1 === endHour;

            const StartDateAt = start
                .set('hours', hour)
                .set('minutes', hour === startHour ? startMinutes : 0)
                .format('YYYY-MM-DDTHH:mm:ssZ');
            const EndDateAt = end
                .set('hours', hour + 1)
                .set('minutes', hour + 1 === endHour ? endMinutes : 0)
                .format('YYYY-MM-DDTHH:mm:ssZ');

            if (endMinutes && hour + 1 === endHour) {
                slots.push({
                    ...interval,
                    Id: v4(),
                    SlotIndex: hour,
                    SlotDate: interval.SlotDate ?? interval.Date,
                    StartDateAt,
                    EndDateAt: end
                        .set('hours', hour + 1)
                        .set('minutes', 0)
                        .format('YYYY-MM-DDTHH:mm:ssZ'),
                    SlotPercent: isStartHour ? startPercent : 100,
                    IsSlotStart: isStartHour,
                    IsSlotEnd: false
                });
                slots.push({
                    ...interval,
                    Id: v4(),
                    SlotIndex: hour + 1,
                    SlotDate: interval.SlotDate ?? interval.Date,
                    StartDateAt: end
                        .set('hours', hour + 1)
                        .set('minutes', 0)
                        .format('YYYY-MM-DDTHH:mm:ssZ'),
                    EndDateAt,
                    SlotPercent: isEndHour ? endPercent : 100,
                    IsSlotStart: false,
                    IsSlotEnd: isEndHour
                });
            } else {
                slots.push({
                    ...interval,
                    Id: v4(),
                    SlotIndex: hour,
                    SlotDate: interval.SlotDate ?? interval.Date,
                    StartDateAt,
                    EndDateAt,
                    SlotPercent: isStartHour ? startPercent : isEndHour ? endPercent : 100,
                    IsSlotStart: isStartHour,
                    IsSlotEnd: isEndHour
                });
            }
        }
    });

    return slots;
};
