import { ApiClient } from './SupabaseContext';

// Функция для добавления функциональности Keycloak в SupabaseClient
export const addKeycloakAuthSupport = (client: ApiClient): ApiClient => {
    // Преобразуем клиент в расширенный интерфейс
    const keycloakClient = client as ApiClient;

    // Добавляем поля для хранения данных Keycloak
    keycloakClient.keycloak_access_token;
    keycloakClient.auth_provider = 'keycloak';

    // Добавляем методы для управления данными Keycloak
    keycloakClient.keyCloakLogout = () => {
        keycloakClient.keycloak_access_token = undefined;
    };

    keycloakClient.authKeycloak = async (username: string, password: string) => {
        // console.debug('authKeycloak');
        const KEYCLOAK_URL = window.env.KEYCLOAK_URL;

        if (!KEYCLOAK_URL) {
            throw new Error('Keycloak URL not found');
        }

        const response = await fetch(KEYCLOAK_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                grant_type: 'password',
                client_id: 'itvectura-frontend',
                username: username,
                password: password
            })
        });

        if (!response.ok) {
            throw new Error('Failed to authenticate with Keycloak');
        }

        const data = await response.json();

        // Сохраняем данные авторизации Keycloak
        console.log('Keycloak token data ', data);
        keycloakClient.keycloak_access_token = data.access_token;
    };

    return keycloakClient;
};
