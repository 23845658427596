import { memo } from 'react';
import { Flex, TabsProps } from 'antd';
import { Collapse } from 'antd-mobile';

import './MobileView.scss';
import { ANY_DATA } from '../../../../../modules/services/backend-api/generated_types';

export interface MobileViewProps {
    tabs: TabsProps['items'];
    layout: ANY_DATA;
}

export const MobileView = memo<MobileViewProps>(({ tabs = [], layout }) => {
    const t = tabs.filter(
        (t) => !layout?.LayoutAreas?.[t.key === 'common_info' ? 'Main' : t.key]?.IsHidden
    );

    // console.log(t);

    return (
        <Flex className={'mobile_view_detail'} vertical>
            {t?.map((tab, i) => {
                return (
                    <Collapse key={tab.key + i} defaultActiveKey={['common_info']}>
                        <Collapse.Panel
                            className={'mobile_view_detail__collapse_panel'}
                            key={tab.key}
                            title={<strong>{tab.label}</strong>}
                        >
                            {tab.children}
                        </Collapse.Panel>
                    </Collapse>
                );
            })}
        </Flex>
    );
});
