import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { ReactNode, createContext, useContext } from 'react';
import { addKeycloakAuthSupport } from './keycloak';

export const supabaseStorageKey = 'sb-studio-auth-token';
export type ApiClient = SupabaseClient<any, 'public', any> & {
    keycloak_access_token?: string;
    auth_provider: string;
    isAuthenticated: boolean;
    authKeycloak: (username: string, password: string) => void;
    keyCloakLogout: () => void;
};

// Create a new Supabase client instance with the given URL and key
const createSupabaseClient: () => ApiClient = () => {
    let client: ApiClient = {} as ApiClient;
    const authProvider = window.env.AUTH_PROVIDER || 'supabase';

    //supabase
    if (authProvider === 'supabase') {
        const supabaseUrl = window.env.SUPABASE_URL;
        const supabaseAnonKey = window.env.SUPABASE_ANON_KEY;
        const supabaseClient = createClient(`${supabaseUrl}`, `${supabaseAnonKey}`, {
            auth: { storageKey: supabaseStorageKey }
        });
        (supabaseClient as Record<string, any>)['auth_provider'] = 'supabase';
        client = supabaseClient as ApiClient;

        // client = {
        //     ...supabaseClient,
        //     auth_provider: 'Supabase'
        // } as ApiClient;
    }
    // keycloak
    else if (authProvider === 'keycloak') {
        client = addKeycloakAuthSupport(client);
    }
    // остальных провайдеров не подерживаем
    else {
        throw new Error('Unsupported authentication provider');
    }

    return client;
};

export const supabaseClient = createSupabaseClient();

export const SupabaseContext = createContext<SupabaseClient | null>(null);

// FIX: УСТАРЕВШЕЕ, используем импорт supabaseClient
export const useSupabase = (): SupabaseClient<any, 'public', any> => {
    const context = useContext(SupabaseContext);
    if (!context) {
        throw new Error('useSupabase must be used within a SupabaseProvider');
    }
    return context;
};

export const SupabaseProvider = ({ children }: { children: ReactNode }) => {
    // TODO: try this instead of global var supabase!
    // const [supabase, setSupabase] = useState<SupabaseClient | null>(null);

    return <SupabaseContext.Provider value={supabaseClient}>{children}</SupabaseContext.Provider>;
};
