import { Input, InputProps } from 'antd';
import { type InputHTMLAttributes, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { isDefined } from 'is-lite/exports';

interface StringFieldProps extends Omit<InputProps, 'onChange'> {
    onChange?: (value: InputHTMLAttributes<HTMLInputElement>['value'] | bigint) => void;
}

export const StringField = memo<StringFieldProps>(({ value, onChange, readOnly, ...props }) => {
    const { t } = useTranslation();

    const [innerValue, setInnerValue] = useState(value);

    useDebounce(
        () => {
            if (onChange && isDefined(innerValue)) {
                onChange(innerValue);
            }
        },
        400,
        [innerValue]
    );

    const v = innerValue ?? value;
    const readOnlyV = value;

    return (
        <Input
            {...props}
            placeholder={t('no_value') as string}
            value={readOnly ? readOnlyV : v}
            onChange={(e) => setInnerValue(e.target.value)}
        />
    );
});
