import './FieldsBox.scss';
import { HelpIcon } from 'smart/ui';
import { memo, useMemo } from 'react';
import { Descriptions, DescriptionsProps, Flex } from 'antd';
import { EmptyMarker } from '../../../../../ui';

export type FieldsBoxItemType = NonNullable<DescriptionsProps['items']>[number] & {
    vertical?: boolean;
    description?: string;
};

export interface FieldsBoxProps {
    items: FieldsBoxItemType[];
    size: 'small' | 'middle' | 'default';
    column?: DescriptionsProps['column'];
    labelWidth?: number | string;
    contentWidth?: number | string;
    longestElementWidth?: number;
    bordered?: boolean;
    contentStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    helpIconPlacement?: 'end' | 'start';
    layout?: 'vertical' | 'horizontal';
}

const DEFAULT_LABEL_WIDTH = '100%';

const getLabelStyle = (style?: React.CSSProperties) => ({
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    // padding: 5,
    // width: labelWidth || 'auto',
    // width: isNumber(labelWidth) ? labelWidth - 19 : `calc(${labelWidth} - 19px)`,
    width: DEFAULT_LABEL_WIDTH,
    display: 'flex',
    // backgroundColor: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...style
});

const getContentStyle = (contentWidth?: number | string, style?: React.CSSProperties) => ({
    // paddingLeft: 5,
    alignSelf: 'center',
    width: contentWidth || 'calc(100% - 250px)',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    ...style
});

export const MobileFieldsBox = memo<FieldsBoxProps>(
    ({
        items,
        contentWidth,
        bordered = true,
        labelStyle,
        contentStyle,
        size = 'default',
        layout = 'horizontal'
    }) => {
        const tooltipedItems = useMemo(
            () =>
                items.map((item) => ({
                    ...item,
                    label: item.label ? (
                        <HelpIcon
                            style={{ width: 'unset' }}
                            text={item.description}
                            placement={'end'}
                        >
                            {item.label}
                        </HelpIcon>
                    ) : undefined,
                    children: item.children ?? <EmptyMarker size={'small'} />
                })),
            [items]
        );

        const span1 = tooltipedItems?.filter((item) => item.span !== 2) || [];
        const span2 = tooltipedItems?.filter((item) => item.span === 2) || [];
        const vertical = tooltipedItems?.filter((item) => item.vertical) || [];

        return (
            <Flex vertical gap={5}>
                {!!span1.length && (
                    <Descriptions
                        layout={layout}
                        bordered={bordered}
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(labelStyle)}
                        contentStyle={getContentStyle(contentWidth, contentStyle)}
                        items={tooltipedItems}
                        column={1}
                    />
                )}
                {!!span2.length && (
                    <Descriptions
                        layout={layout}
                        bordered={true}
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(labelStyle)}
                        contentStyle={getContentStyle(contentWidth, contentStyle)}
                        items={span2}
                        column={1}
                    />
                )}
                {!!vertical.length && (
                    <Descriptions
                        bordered={bordered}
                        layout="vertical"
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(labelStyle)}
                        contentStyle={getContentStyle(
                            contentWidth,
                            bordered
                                ? {
                                      ...contentStyle,
                                      padding: 0
                                  }
                                : contentStyle
                        )}
                        items={vertical}
                        column={1}
                    />
                )}
            </Flex>
        );
    }
);
