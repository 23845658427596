import { Button, Menu, MenuProps, Space } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'antd-mobile';
import { useBoolean } from 'react-use';
import { EllipsisOutlined } from '@ant-design/icons';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

interface MobileCardToolbarProps {
    actionsMenuItems: MenuItem[];
    reportsMenuItems: MenuItem[];
    followsMenuItems: MenuItem[];
}

export const MobileCardToolbar = memo<MobileCardToolbarProps>(
    ({ actionsMenuItems, followsMenuItems, reportsMenuItems }) => {
        const { t } = useTranslation();

        const [actionsPopup, setActionsPopup] = useBoolean(false);
        // const [reportsPopup, setReportsPopup] = useBoolean(false);
        // const [followsPopup, setFollowsPopup] = useBoolean(false);

        const buttonActions = actionsMenuItems.filter((action) => action.isButton);
        //
        // console.log(actionsMenuItems);

        return (
            <>
                <Space.Compact block>
                    {buttonActions.map((action) => (
                        <Button
                            key={action.key}
                            block
                            // icon={action.icon}
                            onClick={action.onClick}
                            children={action.label}
                        />
                    ))}
                    <Button
                        block={!buttonActions.length}
                        style={{ width: buttonActions.length ? '20%' : undefined }}
                        icon={<EllipsisOutlined />}
                        onClick={() => setActionsPopup(true)}
                    />
                    {/* {actionsMenuItems.length ? ( */}
                    {/*    <Button block onClick={() => setActionsPopup(true)}> */}
                    {/*        {t('actions')} */}
                    {/*    </Button> */}
                    {/* ) : null} */}
                    {/* {reportsMenuItems.length ? ( */}
                    {/*    <Button block onClick={() => setReportsPopup(true)}> */}
                    {/*        {t('reports')} */}
                    {/*    </Button> */}
                    {/* ) : null} */}
                    {/* {followsMenuItems.length ? ( */}
                    {/*    <Button block onClick={() => setFollowsPopup(true)}> */}
                    {/*        {t('follows')} */}
                    {/*    </Button> */}
                    {/* ) : null} */}
                </Space.Compact>

                <Popup
                    visible={actionsPopup}
                    onMaskClick={() => setActionsPopup(false)}
                    onClose={() => setActionsPopup(false)}
                    bodyStyle={{ height: 'max-content' }}
                >
                    <Menu
                        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }}
                        mode="inline"
                        onClick={() => {
                            setActionsPopup(false);
                        }}
                        items={[
                            {
                                key: 'actions',
                                label: t('actions'),
                                children: actionsMenuItems
                            },
                            {
                                key: 'reports',
                                label: t('reports'),
                                children: reportsMenuItems
                            },
                            {
                                key: 'follows',
                                label: t('follows'),
                                children: followsMenuItems
                            }
                        ]}
                    />
                </Popup>

                {/* <Popup */}
                {/*    visible={actionsPopup} */}
                {/*    onMaskClick={() => setActionsPopup(false)} */}
                {/*    onClose={() => setActionsPopup(false)} */}
                {/*    bodyStyle={{ height: 'max-content' }} */}
                {/* > */}
                {/*    <Menu */}
                {/*        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }} */}
                {/*        mode="inline" */}
                {/*        onClick={() => { */}
                {/*            setActionsPopup(false); */}
                {/*        }} */}
                {/*        items={actionsMenuItems} */}
                {/*    /> */}
                {/* </Popup> */}
                {/* <Popup */}
                {/*    visible={reportsPopup} */}
                {/*    onMaskClick={() => setReportsPopup(false)} */}
                {/*    onClose={() => setReportsPopup(false)} */}
                {/*    bodyStyle={{ height: 'max-content' }} */}
                {/* > */}
                {/*    <Menu */}
                {/*        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }} */}
                {/*        mode="inline" */}
                {/*        onClick={() => { */}
                {/*            setReportsPopup(false); */}
                {/*        }} */}
                {/*        items={reportsMenuItems} */}
                {/*    /> */}
                {/* </Popup> */}
                {/* <Popup */}
                {/*    visible={followsPopup} */}
                {/*    onMaskClick={() => setFollowsPopup(false)} */}
                {/*    onClose={() => setFollowsPopup(false)} */}
                {/*    bodyStyle={{ height: 'max-content' }} */}
                {/* > */}
                {/*    <Menu */}
                {/*        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }} */}
                {/*        mode="inline" */}
                {/*        onClick={() => { */}
                {/*            setFollowsPopup(false); */}
                {/*        }} */}
                {/*        items={followsMenuItems} */}
                {/*    /> */}
                {/* </Popup> */}
            </>
        );
    }
);
