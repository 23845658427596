import { createClient } from '@supabase/supabase-js';
import { ApiClient, AuthTokenResponsePassword, Session } from './types';
import { isTokenExpired } from './keycloakClient';

export const supabaseStorageKey = 'sb-studio-auth-token';
export // Функция для создания клиента с поддержкой Supabase
const createSupabaseClient = (): ApiClient => {
    const supabaseUrl = window.env.SUPABASE_URL;
    const supabaseAnonKey = window.env.SUPABASE_ANON_KEY;
    const supabaseClient = createClient(`${supabaseUrl}`, `${supabaseAnonKey}`, {
        auth: { storageKey: supabaseStorageKey }
    });
    // let session: Session = window.localStorage.getItem('session');
    // let acessToken = null;
    // if (session) {
    //     session = JSON.parse(session);
    //     acessToken = session?.data?.access_token;
    // }

    const client: ApiClient = {
        authProvider: 'supabase',
        session: null,
        accessToken: null,
        async getUser() {
            const { data, error } = await supabaseClient.auth.getUser();
            if (error) throw error;
            return {
                data: { id: data?.user?.id, name: data?.user?.email, email: data?.user?.email }
            };
        },

        async getSession() {
            const { data, error } = await supabaseClient.auth.getSession();
            if (error) throw error;

            if (data) {
                const session = data.session;
                if (!session) {
                    if (
                        window.location.pathname.includes('/login') ||
                        window.location.pathname.includes('/signup') ||
                        window.location.pathname.includes('/verify') ||
                        window.location.pathname.includes('/invited')
                    ) {
                        this.session = null;
                        this.accessToken = null;
                        return null;
                    }

                    throw new Error('Нет сессии');
                }

                const isTokenExpired = () => {
                    const expiresAt = session?.expires_at || 0;
                    return Date.now() / 1000 > expiresAt;
                };

                if (isTokenExpired()) {
                    await this.refreshAccessToken();
                } else {
                    // Токен валиден, обновляем данные из текущей сессии
                    this.session = {
                        data: {
                            access_token: session.access_token,
                            refresh_token: session.refresh_token,
                            user: session.user
                        }
                    };
                    this.accessToken = session.access_token;
                }
                return this.session;
            }
            throw new Error('Данные для определения сессии пусты');
        },

        async refreshAccessToken() {
            const { data: refreshData, error: refreshError } =
                await supabaseClient.auth.refreshSession();
            if (refreshError) {
                console.error('Failed to refresh session:', refreshError.message);
                this.session = null;
                this.accessToken = null;
                // window.localStorage.removeItem('session'); // очищаем сохраненную сессию
                throw new Error('Сессия истекла, требуется повторная авторизация');
            }

            // Обновляем данные сессии из успешного результата refreshSession
            const refreshedSession = refreshData.session;
            this.session = {
                data: {
                    access_token: refreshedSession?.access_token,
                    refresh_token: refreshedSession?.refresh_token,
                    user: refreshedSession?.user
                }
            };
            this.accessToken = refreshedSession?.access_token;
            // Сохраняем обновленную сессию в localStorage
            // window.localStorage.setItem('session', JSON.stringify(this.session));
        },
        async setSession(accessToken: string, refreshToken: string) {
            await supabaseClient.auth.setSession({
                access_token: accessToken,
                refresh_token: refreshToken
            });
            this.session = { data: { access_token: accessToken, refresh_token: refreshToken } };
            // window.localStorage.setItem('session', JSON.stringify(this.session));
            return { session: this.session?.data, user: this.session?.data?.user };
        },
        async isAuthenticated() {
            return true;
        },
        async signOut() {
            await supabaseClient.auth.signOut();
        },
        async signIn(email: string, password: string): Promise<AuthTokenResponsePassword> {
            const { data, error } = await supabaseClient.auth.signInWithPassword({
                email,
                password,
                options: {
                    data: {
                        roles: ['Guest'],
                        groups: ['Guest'],
                        default_group: 'Guest'
                    }
                }
            });

            // Устанавливаем токен и сессию, если они получены
            this.accessToken = data?.session?.access_token;
            this.session = {
                data: {
                    access_token: data?.session?.access_token,
                    refresh_token: data?.session?.refresh_token,
                    user: {
                        id: data.user?.id,
                        name: data.user?.email,
                        email: data.user?.email
                    }
                },
                error: error?.message
            };
            // window.localStorage.setItem('session', JSON.stringify(this.session));
            // Возвращаем объект с данными и ошибками
            if (error) {
                // console.debug('signIn error', error);
                return {
                    data: undefined,
                    error: error.message
                };
            }
            // console.debug('signIn not error ', data);
            return {
                data: {
                    user: {
                        id: data.user?.id,
                        name: data.user?.email,
                        email: data.user?.email
                    },
                    session: {
                        access_token: data.session?.access_token,
                        refresh_token: data.session?.refresh_token,
                        user: {
                            id: data.user?.id,
                            name: data.user?.email,
                            email: data.user?.email
                        }
                    }
                },
                error: undefined
            };
        },
        // verifyOtp: async (data: any) => {
        //     console.log('verifyOtp', data);
        // },
        onAuthStateChange(callback) {
            return supabaseClient.auth.onAuthStateChange(callback);
        },
        // signUp() {
        //     console.log('signUp');
        // },

        storage: {
            listBuckets: async () => {
                const { data, error } = await supabaseClient.storage.listBuckets();
                if (error) throw error;
                return { data, error };
            },
            uploadFile: async (bucket: string, path: string, file: File) => {
                const { data, error } = await supabaseClient.storage
                    .from(bucket)
                    .upload(path, file);
                if (error) throw error;
                return { data, error };
            },
            downloadFile: async (bucket: string, path: string) => {
                const { data, error } = await supabaseClient.storage.from(bucket).download(path);
                if (error) throw error;
                return { data, error };
            },
            removeFile: async (bucket: string, path: string) => {
                const { data, error } = await supabaseClient.storage.from(bucket).remove([path]);
                if (error) throw error;
                return { data, error };
            },
            upsertMdFile: async (bucket: string, filename: string, markdown: string) => {
                const { data, error } = await supabaseClient.storage
                    .from(bucket)
                    .update(filename, markdown, {
                        contentType: 'application/octet-stream',
                        upsert: true
                    });
                if (error) throw error;
                return { data, error };
            }
        }
    };

    return client;
};
