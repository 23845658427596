import { useEffect, useState } from 'react';
import { useBoolean, useEffectOnce } from 'react-use';
import { IHookStateSetAction } from 'react-use/lib/misc/hookState';

import { InfoUser } from 'modules/services/backend-api/generated_models';
import { toJS } from 'mobx';
import { supabaseClient } from '../../contexts/SupabaseContext/SupabaseContext';
import { metaStore } from '../../../../utils/store/MetaStore';
import { Meta } from '../../../services/backend-api/generated_info';
import { TypeAuth, UseAuthUser } from 'modules/auth/hooks/useAuthUser';
import { client } from 'modules/supabase/contexts/SupabaseContext/ApiContext';
import { User } from 'modules/supabase/contexts/SupabaseContext/types';

type UseSupabaseUser = {
    user: User | null;
    loading: boolean;
    isPowerUser: boolean;
    setUser: (state: IHookStateSetAction<User | null>) => void;
    provider: TypeAuth;
};

export const isRoleExists = async (userUuid: string, role = 'Admin') => {
    let userId = '';

    let response = await window.api.select({
        meta: 'InfoUsers',
        filters: `UserUuid=eq.${userUuid}`
    });

    if (response && response.objects.length > 0) {
        userId = response.objects[0].Id;
    }

    if (!userId) return false;

    response = await window.api.select({
        meta: 'InfoUserRoles',
        filters: `Role=eq.${role}&User=eq.${userId}`
    });

    return response && response.objects.length > 0;
};

export const useSupabaseUser = (): UseAuthUser => {
    const [provider, setProvider] = useState<TypeAuth>();
    const [user, setUser] = useState<User['data']>();
    const [loading, setLoading] = useBoolean(true);
    const [isPowerUser, setIsPowerUser] = useBoolean(false);

    useEffectOnce(() => {
        const init = async () => {
            try {
                const { data: user, error } = await client.getUser();
                setUser(user);
                if (await isRoleExists(user?.id ?? '', 'Admin')) setIsPowerUser(true);
                setProvider(await client.authProvider);
            } catch (error) {
                // console.debug('useSupabaseUser error', error);
            } finally {
                setLoading(false);
            }

            // if (supabaseClient.auth_provider === 'supabase') {
            //     const { data } = await supabaseClient.auth.getSession();

            //     setUser(data.session?.user ?? null);
            //     setLoading(false);

            //     if (!data.session) return;

            //     if (await isRoleExists(data.session?.user.id, 'Admin')) setIsPowerUser(true);
            // } else if (supabaseClient.auth_provider === 'keycloak') {
            //     setUser('ЗАТЫЧКА');
            //     setLoading(false);
            // }
        };

        init();
    });

    return { user, loading, isPowerUser, setUser, provider };
};

export const useUserData = () => {
    const [data, setData] = useState<InfoUser | null>(null);
    const [info, setInfo] = useState<Meta | null>(null);
    const [loading, setLoading] = useState(false);
    const { user, isPowerUser, loading: loadingSup } = useSupabaseUser();

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            const info =
                toJS(metaStore.meta.get('InfoUsers')?.info) ??
                (await metaStore.getInfo('InfoUsers'));

            const data =
                toJS(metaStore.meta.get('InfoUsers')?.select) ??
                (await metaStore.makeSelect({
                    meta: 'InfoUsers',
                    filters: `UserUuid=eq.${user?.id}`
                }));

            if (info) setInfo(info);
            if (data) setData(data?.objects[0] as InfoUser);

            setLoading(false);
        };

        if (user) init();
    }, [user]);

    return { data: data ? data : user, info, isPowerUser, loading: loading || loadingSup };
};
