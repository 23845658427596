import {
    BellOutlined,
    ClearOutlined,
    LogoutOutlined,
    SearchOutlined,
    UserOutlined
} from '@ant-design/icons';
import { User } from '@supabase/supabase-js';
import { Badge, Button, Flex, Layout, Popover, Space } from 'antd';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { useUserData } from 'modules/supabase/utils/hooks/useSupabaseUser';
import { routeStore, springboardStore, useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { ButtonWithTooltips } from 'ui';

import { Changelog } from '../Changelog/Changelog';
import { AppSearch } from './components';
import { fnv1aHash } from '../../../../../smart/utils';
import './Header.scss';
import { useHandlerRun } from '../../../../../smart/utils/hooks';
import { useLocation } from 'react-router-dom';
import { client } from 'modules/supabase/contexts/SupabaseContext/ApiContext';

interface HeaderProps extends React.PropsWithChildren {
    text?: string;
    toggleMenuButton?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    style?: React.CSSProperties;
    showUserProfile?: boolean;
    user: User | null;
    setUser: (user: User | null) => void;
    numNotifications: number;
}

export const Header = observer<HeaderProps>(
    ({
        toggleMenuButton,
        breadcrumbs,
        children,
        style,
        user,
        setUser,
        showUserProfile = true,
        numNotifications
    }) => {
        const { data, info } = useUserData();
        const { t, i18n } = useTranslation();
        const navigate = useStoreNavigate();
        const { pathname, search, state } = useLocation();
        const { run } = useHandlerRun();
        // const { isPowerUser } = useSupabaseUser();

        const isSmallTablet = useMedia('(max-width: 620px)');
        const isBigMobile = useMedia('(max-width: 480px)');

        // const [isMobileSearchOpen, setIsMobileSearchOpen] = useBoolean(false);

        const handleCheckProfile = () => {
            // console.log(data?.Name);
            navigate(`/settings/users/${data?.Id}`, {
                state: {
                    data,
                    pageTitle: `${data?.Name?.[i18n.language === 'tech' ? 'en' : i18n.language]} (${
                        info?.SingularName?.[i18n.language === 'tech' ? 'en' : i18n.language]
                    })`,
                    cacheKey: fnv1aHash(`InfoUsers_${data?.Id}_view`)
                }
            });
        };

        const handleLogout = async () => {
            setUser(null);
            routeStore.clear();
            metaStore.clear();
            springboardStore.reset();
            await client.signOut();
            // await supabaseClient.auth.signOut();
        };

        if (!user) return null;

        const userName =
            data?.Name?.[i18n.language === 'tech' ? 'en' : i18n.language] || data?.Email;

        return (
            <Layout.Header className="header" style={style}>
                <div className="header__container">
                    <div className="header__left">
                        {toggleMenuButton}

                        {!isBigMobile && <AppSearch />}

                        {breadcrumbs && <div className="header__breadcrumbs">{breadcrumbs}</div>}
                    </div>
                    <div className="header__content">{children}</div>

                    <Flex gap={5} align="center" justify="center" className="header__right">
                        <Space.Compact>
                            {isBigMobile && (
                                <Popover content={<AppSearch />}>
                                    <Button
                                        size={isBigMobile ? 'large' : 'middle'}
                                        icon={<SearchOutlined />}
                                        type="text"
                                        // onClick={() => setIsMobileSearchOpen(true)}
                                    />
                                </Popover>
                            )}
                            {showUserProfile && (
                                <ButtonWithTooltips
                                    id="profile"
                                    tooltipTitle={t('profile')}
                                    type="text"
                                    className="header__profile"
                                    icon={<UserOutlined />}
                                    onClick={handleCheckProfile}
                                    size={isBigMobile ? 'large' : 'middle'}
                                >
                                    {!isSmallTablet && userName}
                                </ButtonWithTooltips>
                            )}
                            <ButtonWithTooltips
                                type={'text'}
                                className={''}
                                icon={<ClearOutlined />}
                                onClick={async () => {
                                    window.location.reload();
                                    // routeStore.clear();
                                    // metaStore.clear();
                                    // navigate({ pathname, search }, { state }, { replace: true });
                                    //
                                    // await metaStore.getRoutes();
                                    // await Promise.all([
                                    //     metaStore.getMenu(),
                                    //     metaStore.makeSelect({ meta: 'InfoMeta' }),
                                    //     metaStore.getInfo('DocOrders'),
                                    //     metaStore.getInfo('DocTrips'),
                                    //     metaStore.getInfo('InfoObjectProperties')
                                    // ]);

                                    await run({
                                        meta: 'InfoMeta',
                                        args: {},
                                        Action_Id: 'e5911d48-8705-11ef-82aa-000000cdd23e',
                                        handler: 'ClearAllRedisCaches',
                                        ids: []
                                    });
                                }}
                            />
                            <LanguageSwitcher />
                            <Badge dot={numNotifications > 0}>
                                <ButtonWithTooltips
                                    size={isBigMobile ? 'large' : 'middle'}
                                    id="notifications"
                                    tooltipTitle={t('notifications')}
                                    className=""
                                    type="text"
                                    icon={<BellOutlined style={{ fontSize: 18 }} />}
                                    onClick={() => {
                                        const route = metaStore.meta
                                            .get('all')
                                            ?.routes?.find(
                                                (r) =>
                                                    r.meta === 'InfoNotifications' &&
                                                    r.path.includes('settings') &&
                                                    r.path.includes('User.Id')
                                            );
                                        if (route) {
                                            const menu = metaStore.meta
                                                .get('all')
                                                ?.menu?.items?.find((m) => m.path === route.path);

                                            if (menu) {
                                                navigate(
                                                    {
                                                        pathname: route.path.split('?')[0],
                                                        search: ''
                                                    },
                                                    {
                                                        state: {
                                                            pageTitle:
                                                                route.layout.Name?.[i18n.language],
                                                            filterString: route.path.split('?')[1],
                                                            cacheKey: fnv1aHash(
                                                                `${route.id}_${
                                                                    route.layout.Name?.[
                                                                        i18n.language
                                                                    ]
                                                                }`
                                                            )
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                />
                            </Badge>

                            <Changelog />

                            <ButtonWithTooltips
                                size={isBigMobile ? 'large' : 'middle'}
                                id="logout"
                                tooltipTitle={t('logout')}
                                tooltipPlacement="bottomLeft"
                                className="header__logout"
                                type="text"
                                icon={<LogoutOutlined />}
                                onClick={handleLogout}
                            />
                        </Space.Compact>
                    </Flex>
                </div>
            </Layout.Header>
        );
    }
);
