import { IHookStateSetAction } from 'react-use/lib/misc/hookState';
import { useKeycloakUser } from './useKeycloakUser';
import { useSupabaseUser } from '../../supabase/utils/hooks/useSupabaseUser';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { client } from 'modules/supabase/contexts/SupabaseContext/ApiContext';
import { User } from 'modules/supabase/contexts/SupabaseContext/types';
export type TypeAuth = 'supabase' | 'keycloak';
export type UseAuthUser = {
    user?: User['data'];
    loading: boolean;
    isPowerUser: boolean;
    provider: TypeAuth;
    setUser: (state: IHookStateSetAction<User['data'] | null>) => void;
};

export const useAuthUser = (): UseAuthUser => {
    const authProvider = client.authProvider || 'supabase';
    // const authProvider = supabaseClient.auth_provider || 'supabase';
    if (authProvider === 'supabase') {
        return useSupabaseUser();
    } else if (authProvider === 'keycloak') {
        return useKeycloakUser();
    }
    throw new Error(`Unknown auth provider: ${authProvider}`);
};
