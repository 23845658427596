import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterField } from '../../components/SmartTableFilterMenu/types';
import {
    buildFilter,
    convertComplexFilterToSupabaseFilter
} from '../../components/SmartTableFilterMenu/utils';

export const useFilterToStringConverter = () => {
    const { i18n } = useTranslation();

    const converter = useCallback(
        (filters: FilterField[]) => {
            const convertedToSupabase = convertComplexFilterToSupabaseFilter(
                filters,
                i18n.language
            );

            return (
                buildFilter(convertedToSupabase)
                    .filter(
                        (f) =>
                            !f.includes('eq.undefined') ||
                            (f.includes('eq.undefined') && f.includes('or('))
                    )
                    .join('&')
                    .replaceAll('neq.undefined', 'isnot.null')
                    // .replaceAll('in.[]', 'isnot.null')
                    .replaceAll('eq.undefined', 'is.null')
            );
        },
        [i18n.language]
    );

    return converter;
};
